<template>
  <b-col md="7">
    <div class="section-title">
      <h4 class="pl-0 pr-3 pt-3 pb-3">
        <span class="pl-0">Overview</span>
      </h4>
    </div>
    <p class="pr-20"
       style="margin-top: -30px">
      There are several steps in the process of mortgaging a property. Below are some of the forms that we complete to gather information each step of way. Any information added here is sent instantly to your mortgage advisor for processing.
    </p>
  </b-col>
</template>

<script>
export default {
  name: 'Introduction'
}
</script>