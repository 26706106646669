<template>
  <div>
    <div class="section-title">
      <h4 class="p-3 mb-2">
        <span>Document Requests</span>
      </h4>
    </div>

    <p class="mb-32">
      {{ title }}<br><br>
      PDF's are preferable although banks may accept photos of documents provided the full document is in the
      picture and the image is clear. Images should not show more than one document. Avoid
      holding the documents whilst taking a photo.
    </p>

    <div>
      <b-row>
        <b-col
            v-for="(request, index) in transaction.document_requests.filter(doc_request => doc_request.id !== transaction.additional_supporting_document_request && !doc_request.accepted)"
            :key="index + 'doc-request'"
            class="pr-30"
            sm="12"
            md="12"
            xl="6"
        >
          <DocumentRequest :request="request"
                           :transaction="transaction"
                           @refresh="getDocumentRequests"/>
        </b-col>
      </b-row>
    </div>

    <template v-if="additionalInformationDocumentRequest">
      <div>
        <b-collapse id="collapse-1" class="mt-2">
          <b-row>
            <b-col
                class="pr-30"
                sm="12"
                md="12"
                xl="6"
            >
              <DocumentRequest :request="additionalInformationDocumentRequest"
                               :transaction="transaction"
                               @refresh="getDocumentRequests"/>
            </b-col>
          </b-row>
        </b-collapse>

        <b-button class="mb-30" v-b-toggle.collapse-1 variant="secondary">Upload Additional information</b-button>

      </div>
    </template>
  </div>
</template>

<script>
import {http} from "@/services";
import {clientHelpers} from "@/mixins/clientHelpers";

import DocumentRequest from "./DocumentRequest";

export default {
  name: "DocumentRequestSection",
  components: {DocumentRequest},
  mixins: [clientHelpers],
  props: {
    value: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    transaction: {
      set(value) {
        this.$emit("input",
            value);
      },
      get() {
        return this.value;
      },
    },
    additionalInformationDocumentRequest() {
      return this.transaction.document_requests.find(doc_request => doc_request.id === this.transaction.additional_supporting_document_request)
    }
  },
  data() {
    return {};
  },
  methods: {
    getDocumentRequests() {
      http.get("document_requests",
          {
            params: {transaction: this.transaction.id},
          }).then((response) => {
        this.transaction.document_requests = response.data;
      }).catch((error) => {
        console.log("error fetching document requests",
            error);
      });
    },
  },
};
</script>
