<template>
  <b-modal
      v-model="showModal"
      body-class="modal-form"
      modal-class="modal-style-one modal-background-white questionnaire"
      no-close-on-backdrop
      scrollable
      size="lg"
      title="Appointment Booking"
      :hide-footer="!show.modalFooter"
      @hidden="clear"
      @shown="shown"
      @cancel="cancel"
  >
    <div v-if="bookingInformationHTML" v-dompurify-html="bookingInformationHTML"></div>

    <BookingForm
        ref="bookingForm"
        :transaction="transaction"
        :appointment-type="appointmentType"
        :host-role="hostRole"
        :show-new-client="showNewClient"
        :event="event"
        :show-confirmation-panel="showConfirmationPanel"
        @confirmed="showConfirmation"
    />

    <div v-if="cancelAppointmentButton && !createdEvent">
      <b-button class="w-100" @click="cancel">{{ cancelText }}</b-button>
    </div>

    <template #modal-footer="{ ok }">

      <div class="w-100 m-0 d-flex">
        <button class="btn btn-backwards white-border" @click="rebook">
          Reschedule
        </button>
        <button class="btn btn-forwards" @click="ok">Done</button>
      </div>

    </template>
  </b-modal>
</template>

<script>
import BookingForm from "@/components/common/appointment/calender/BookingForm";

export default {
  name: "BookingModal",
  components: {
    BookingForm
  },
  props: {
    transaction: {
      type: Object,
      required: false,
    },
    appointmentType: {
      type: String,
      required: true,
    },
    hostRole: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: false,
    },
    event: {
      type: Object,
      required: false,
    },
    showNewClient: {
      type: Boolean,
      default: false
    },
    showConfirmationPanel: {
      type: Boolean,
      default: false
    },
    cancelAppointmentButton: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: 'Continue Without Appointment'
    },
    bookingInformationHTML: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      show: {
        modalFooter: false
      },
      createdEvent: null
    }
  },
  computed: {
    showModal: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    }
  },
  methods: {
    shown() {
      console.log('shown now init form')
        this.$refs.bookingForm.init()
    },
    clear() {
      // this.$refs.bookingForm.clear()
        this.$emit("refresh");
        this.show.modalFooter = false;
        this.createdEvent = null
    },
    rebook() {
      console.log('rebook from booking modal', this.$refs)
      this.show.modalFooter = false;
      this.$nextTick(() => {
        this.$refs.bookingForm.rebook()
      })
    },
    showConfirmation(event) {
      this.$refs.bookingForm.showConfirmation()
      this.show.modalFooter = true;
      this.createdEvent = event
    },
    cancel () {
      this.$emit('cancel')
    }
  },
};
</script>
