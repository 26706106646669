<template>
  <div id="contact">
    <div class="container mb-30">
      <div class="ask-question">
        <h4 class="pl-3 mb-0 pr-3">
          <span>Ask a Question / Submit Request</span>
        </h4>
        <div class="d-flex justify-content-between">
            <textarea
                v-model="form.description"
                class="pt-30 pl-20 pr-20 pb-8 form-control"
                name="question"
                placeholder="Type Your Question Here"
                ></textarea>
          <button :disabled="!form.description" @click="pre_submit" class="btn btn-secondary">Submit</button>
        </div>
      </div>
    </div>

    <AdvisorModal v-model="show.modal" :transaction="transaction" @submit="advisor_selected" />

  </div>
</template>

<script>
import { http } from "@/services";
import { toast } from '@/mixins/toast'
import AdvisorModal from "./AdvisorModal.vue";

export default {
  name: 'Contact',
  mixins: [toast],
  components:{
    AdvisorModal,
  },
  props: {
    transaction: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      show:{
        modal: false,
      },
      form: {
        description: null,
        client: null,
        introducer: null,
        transaction: null,
        type: null,
        advisor: null,
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    advisor_selected(advisor){
      this.form.advisor = null;
      if(advisor.email){
        this.form.advisor = advisor.email;
      }
      this.submit();

    },
    pre_submit(){
      if(this.user.is_client){
        // select advisor
        this.show.modal = true
      }
      else{
        this.submit();
      }
    },
    submit() {
      // creates a task
      if (this.transaction) this.form.transaction = this.transaction.id
      if (this.user.is_introducer) {
        this.form.introducer = this.user.is_introducer.id
        this.form.type = 'Introducer Enquiry'
      }
      else if (this.user.is_client) {
        this.form.client = this.user.is_client.id
        this.form.type = 'Client Enquiry'
      }

      http.post('ask_a_question', this.form).then(
          () => {
            this.message(
                'Your message has been received, we will contact you as soon as possible',
                'Message Received')
            this.form.description = null
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    }
  }
}
</script>
