<template>
  <div>
    <div
        class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon"/>
      </div>
      <h4 class="trans-section-title">Products</h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">
          Here are the status' of products that you are proceeding with.
        </p>
      </b-col>
    </b-row>
    <b-row class="pt-20 trans-section-body">
      <b-col v-for="(mortgage, index) in mortgages"
             :key="`mortgage${index}`"
             md="4">
        <MortgageCard :mortgage="mortgage"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MortgageCard from "./cards/Mortgage";

export default {
  name: "Products",
  components: {
    MortgageCard
  },
  props: {
    mortgages: {
      type: Array,
      required: true,
    },
    previousSectionComplete: {
      type: Boolean,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
};
</script>
