<template>
  <div>
    <div class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon"/>
      </div>
      <h4 class="trans-section-title">
        Client Profile{{ progress.length > 1 ? "s" : null }} </h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">
          Click on the profile{{ progress.length === 2 ? "s" : null }} below to add basic information about your status,
          address, dependants, assets and liabilities. </p>
      </b-col>
    </b-row>
    <b-row class="pt-20 trans-section-body">
      <b-col v-for="(clientProfile, index) in progress"
             :key="`clientProfile${index}`"
             md="4">
        <ClientProfileCard
            v-if="user"
            :class="{'disabled' : clientProfile.client.id !== user.is_client.id }"
            :clientProfile="clientProfile"
            :disabled="clientProfile.client.id !== user.is_client.id"
            :transaction="transaction"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ClientProfileCard from "./cards/ClientProfile";

export default {
  name: "ClientProfiles",
  components: {
    ClientProfileCard,
  },
  props: {
    progress: {
      type: Array,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  }
};
</script>
<style>
.disabled {
  pointer-events: none;
}
</style>