<template>
  <div>
    <div
        class="d-flex align-items-center page-num-container pt-80"
        :class="{'completed': progress.percentage === 100}"
    >
      <div class="page-num"
           :style="'background: conic-gradient(#ffffff 0deg ' + (360/100 * progress.percentage) + 'deg,#f6f7fa 80deg 190deg)'">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon"/>
      </div>
      <h4 class="trans-section-title">Mortgage Requirements</h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">The below questionnaire asks various questions about your specific mortgage requirements. Once completed this can only be edited by your advisor.</p>
      </b-col>
    </b-row>
    <b-row class="pt-20 trans-section-body">
      <b-col md="4">
        <MortgageSurveyCard
            :progress="progress"
            :survey="transaction.mortgage_survey"
            :transaction="transaction"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import MortgageSurveyCard from "./cards/MortgageSurveyCard";

export default {
  name: 'MortgageSurvey',
  components: {MortgageSurveyCard},
  props: {
    transaction: {
      type: Object,
      required: true
    },
    progress: {
      type: Object,
      required: true
    }
  }
}
</script>