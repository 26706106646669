import {clientHelpers} from "@/mixins/clientHelpers";
import {dateFormat} from "@/mixins/dateFormat";

export const progressInsuranceHelpers = {
    mixins: [clientHelpers, dateFormat],
    computed: {
        // transaction ordered and simplified
        clients() {
            return this.transaction.clients.sort((a, b) => a.id - b.id)
        },
        // progress
        clientProfileProgress() {
            // return percentage complete for each client profile
            let result = []
            let tF = [true, false]

            // guards
            if (!this.transaction) return result

            for (let i in this.clients) {
                // init variable
                let completed = 0
                let questions = 0 // amount of required questions
                let percentage = 0
                let errors = []
                let client = this.clients[i]

                // add questions and percentages
                questions += 1
                if (client.profileNameTitle) completed += 1
                else errors.push('Title missing')

                questions += 1
                if (client.profileNameFirst) completed += 1
                else errors.push('First name missing')

                questions += 1
                if (client.profileNameLast) completed += 1
                else errors.push('Last name missing')

                questions += 1
                if (tF.includes(client.profileAliasYN)) completed += 1
                else errors.push('Also known as missing')

                questions += 1
                if (client.profileAliasYN) {
                    questions += 1
                    if (client.profileAlias) {
                        completed += 1
                    } else errors.push('Also known as detail missing')
                }

                // questions += 1
                // if (tF.includes(client.profilePreviousNameYN)) completed += 1
                // else errors.push('Previous name missing')

                // if (client.profilePreviousNameYN) {
                //     // skipp-able questions (may want commenting out)
                //     questions += 3
                //     if (client.profilePreviousName) {
                //         completed += 1
                //     } else errors.push('Previous name details missing')
                //     if (client.profilePreviousNameChangedDate) {
                //         completed += 1
                //     } else errors.push('Previous name date missing')
                //     if (client.profilePreviousNameChangedMethod) {
                //         completed += 1
                //     } else errors.push('Previous name method missing')
                // }

                questions += 1
                if (client.profileSex) completed += 1
                else errors.push('Gender missing')

                questions += 1
                if (client.profileDOB) completed += 1
                else errors.push('Date of birth missing')

                if (client.profileNationality) completed += 1
                else errors.push('Nationality missing')


                // if (tF.includes(client.profileDiplomaticImmunity)) completed += 1
                // else errors.push('Diplomatic status missing')

                questions += 1
                if (client.profileRelationshipStatus) completed += 1
                else errors.push('Relationship status missing')

                questions += 1
                if (tF.includes(client.profileRelationshipCurrentlyYN)) {
                    completed += 1

                    if (client.profileRelationshipCurrentlyYN) {
                        questions += 1
                        if (client.profileRelationshipCurrentStatus) completed += 1
                        else errors.push('Client current relationship status missing')

                        questions += 1
                        if (client.profileRelationshipDuration) completed += 1
                        else errors.push('Client Relationship duration missing')
                    }

                } else errors.push('Currently in a relationship missing')


                questions += 1
                if (tF.includes(client.profileRelationshipPreviousYN)) {
                    completed += 1
                    if (client.profileRelationshipPreviousYN) {
                        questions += 1
                        if (client.profileRelationshipPreviousStatus) completed += 1
                        else errors.push('Previous relationship ending missing')

                        questions += 1
                        if (client.profileRelationshipPreviousPersonName) completed += 1
                        else errors.push('Previous relationship person name missing')
                    }
                } else errors.push('Been in a previous marriage missing')


                // if (client.profileMobileNumber) completed += 1
                // else errors.push('Mobile number missing')
                //
                // if (client.profileEmail) completed += 1
                // else errors.push('Email missing')

                questions += 1
                if (tF.includes(client.profileMarketingConsent)) completed += 1
                else errors.push('Marketing consent missing')

                questions += 1
                if (client.current_address) completed += 1
                else errors.push('Current address is missing')

                // if (client.profileAddressMovedIn) completed += 1
                // else errors.push('Address moved in date missing')
                //
                // if (client.profileAddressOccupancy) completed += 1
                // else errors.push('Address occupancy missing')
                //
                // if (tF.includes(client.profileAddressCorrespondenceYN)) completed += 1
                // else errors.push('Address correspondence missing')

                // let now = new Date()
                // let addressHistoryYearsRequired = 5
                // if (client.profileAddressMovedIn &&
                //     now.setFullYear(now.getFullYear() - addressHistoryYearsRequired) <
                //     new Date(client.profileAddressMovedIn)) {
                //     questions += 1
                //     if (client.previous_address && client.previous_address.length) completed += 1
                //     else errors.push('Previous address history missing')
                // }

                questions += 1
                if (tF.includes(client.children_have)) completed += 1
                else errors.push('Have children missing')

                if (client.children_have) {
                    questions += 1
                    if (client.people && client.people.filter(p => this.isSonOrDaughter(p)).length) completed += 1
                    else errors.push('Children details missing')
                }

                questions += 1
                if (tF.includes(client.profilePregnant)) completed += 1
                else errors.push('Expecting a child missing')

                if (client.profilePregnant) {
                    questions += 1
                    if (client.profilePregnantDueDate) {
                        completed += 1
                    } else errors.push('Expecting a child date missing')
                }

                questions += 1
                if (tF.includes(client.dependants_have)) completed += 1
                else errors.push('Client other dependants missing')

                if (client.dependants_have) {
                    questions += 1
                    if (client.people && client.people.filter(p => this.isDependant(p)).length) completed += 1
                    else errors.push('Dependants details missing')
                }

                questions += 1
                if (tF.includes(client.assets_other_high_value)) completed += 1
                else errors.push('Other high value assets missing')

                if (client.assets_other_high_value) {
                    questions += 1
                    if (client.assets && client.assets.filter(a => !this.assetsOtherNot.includes(a.assetType)).length) completed += 1
                    else errors.push('Other asset details missing')
                }

                questions += 1
                if (tF.includes(client.policies_other_type)) completed += 1
                else errors.push('Other policies missing')

                if (client.policies_other_type) {
                    questions += 1
                    if (client.policies && client.policies.filter(p => !this.policiesOthersNot.includes(p.policyType)).length) completed += 1
                    else errors.push('Other policies details missing')
                }

                questions += 1
                if (tF.includes(client.mortgage_debts)) completed += 1
                else errors.push('Mortgage debts missing')

                if (client.mortgage_debts) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => this.liabilitiesMortgage.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Mortgage details missing')
                }

                questions += 1
                if (tF.includes(client.unsecured_loans)) completed += 1
                else errors.push('Unsecured loans missing')

                if (client.unsecured_loans) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => this.liabilitiesLoans.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Unsecured loan details missing')
                }

                questions += 1
                if (tF.includes(client.credit_card_balances)) completed += 1
                else errors.push('Credit cards missing')

                if (client.credit_card_balances) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => this.liabilitiesCreditCards.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Credit card details missing')
                }

                questions += 1
                if (tF.includes(client.other_credit_agreements)) completed += 1
                else errors.push('Other credit agreements missing')

                if (client.other_credit_agreements) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => !this.liabilitiesOtherNot.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Other credit agreements details missing')
                }
                //
                // questions += 1
                // if (tF.includes(client.profileBankruptcyIVAYN)) completed += 1
                // else errors.push('Bankruptcy missing')
                //
                // questions += 1
                // if (tF.includes(client.profileDebtManagementYN)) completed += 1
                // else errors.push('Debt management missing')

                // questions += 1
                // if (tF.includes(client.profileCCJYN)) completed += 1
                // else errors.push('CCJ missing')
                //
                // questions += 1
                // if (tF.includes(client.profileMissedYN)) completed += 1
                // else errors.push('Missed payment missing')

                // if (client.commitments && client.commitments.length) completed += 1
                // else errors.push('Commitments details missing')

                // calculate percentage
                percentage = Number(Number((completed / questions) * 100).toFixed(0))

                // add percentage to return array
                result.push({percentage: percentage, errors: errors, client: client})
            }
            return result
        },
        clientProfilesSectionComplete() {
            return this.clientProfileProgress.filter(
                c => c.percentage === 100).length === this.clientProfileProgress.length
        },
        currentHandoverStep() {
            if (this.hasHandedOver) return ''
            if (!this.clientProfilesSectionComplete) return 'before_client_profile'
            else if (this.clientProfilesSectionComplete) return 'after_client_profile'
            return 'before_client_profile'
        },
        hasHandedOver() {
            return !!this.transaction.handover_point;
        },
    },
    methods: {
        refreshDocuments() {
            this.$refs.documents.documentsFetch();
        }
    }
}
