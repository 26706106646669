import {clientHelpers} from '@/mixins/clientHelpers'


export const showClientQuestionHelpers = {
    mixins: [clientHelpers],
    methods: {
        // health
        healthIssues(client) {
            return client.health_status;
        },
        // capacity
        capacityIssues(client) {
            return client.capacity_status;
        },
        // hearing and sight
        hearingSightImpairmentStatusDetails(client) {
            return client.hearing_sight_impairment_status;
        },
        // mobility
        mobilityIssues(client) {
            return client.mobility_status;
        },
        // language
        languageIssues(client) {
            return client.language_status;
        },
        // will
        willExisting(estate) {
            return estate.will_existing;
        },
        willExistingOverseas(estate) {
            // requires assets held overseas
            return !!estate.clientData.assets.filter(
                (asset) => !['England', 'Wales', null].includes(asset.assetLocation)
            ).length;
        },
        willExistingDraftsPerson(estate) {
            return this.willExisting(estate);
        },
        willExistingYear(estate) {
            return this.willExisting(estate);
        },
        changesRelationship(estate) {
            return this.willExisting(estate);
        },
        changesRelationshipDetails(estate) {
            return this.willExisting(estate) && estate.changes_relationship;
        },
        changesDependants(estate) {
            return this.willExisting(estate);
        },
        changesDependantsDetails(estate) {
            return this.willExisting(estate) && estate.changes_dependants;
        },
        willChangesAppointed(estate) {
            return this.willExisting(estate);
        },
        willChangesAppointedDetails(estate) {
            return this.willExisting(estate) && estate.changes_appointed;
        },
        willChangesEstate(estate) {
            return this.willExisting(estate);
        },
        willChangesEstateDetails(estate) {
            return this.willExisting(estate) && estate.changes_estate;
        },
        willChangesOther(estate) {
            return this.willExisting(estate);
        },
        willChangesOtherDetails(estate) {
            return this.willExisting(estate) && estate.changes_will_other;
        },

        // financial & inheritance tax
        financialAdvisor(estate) {
            // has savings/investments assets over amount
            let amount = 100000;
            let assets = estate.clientData.assets.filter((asset) =>
                ['Savings', 'Bank Accounts', 'Shares', 'Bonds'].includes(
                    asset.assetType
                )
            );
            return (
                assets.reduce((acc, asset) => acc + asset.assetValue || 0, 0) >
                amount
            );
        },
        mortgage(estate) {
            return !!estate.clientData.liabilities.find((liability) =>
                ['Mortgage', 'Mortgage (BTL)'].includes(liability.liabilityType)
            );
        },
        mortgageNoneDebts(estate) {
            return !!estate.clientData.liabilities.find(
                (liability) =>
                    !['Mortgage', 'Mortgage (BTL)'].includes(
                        liability.liabilityType
                    )
            );
        },
        mortgageEarlyRepaymentCharge(estate) {
            // has a mortgage
            return this.mortgage(estate);
        },

        // business
        businessContinue(estate) {
            return ['Company Director'].includes(
                estate.clientData.profilePrimaryEmployment
            ) || ['Company Director'].includes(
                estate.clientData.profileSecondaryEmployment)
        },
        businessOtherShareHolders(estate) {
            return this.businessContinue(estate) && estate.business_continue;
        },
        businessShareHoldersAgreement(estate) {
            return (
                this.businessOtherShareHolders(estate) &&
                estate.business_other_shareholders
            );
        },
        // divorce and separation
        separated(estate) {
            return estate.clientData.profileRelationshipStatus === 'separated' ||
                estate.clientData.profileRelationshipPreviousStatus === 'separated'
        },
        divorced(estate) {
            return estate.clientData.profileRelationshipStatus === 'divorced' ||
                estate.clientData.profileRelationshipPreviousStatus === 'divorced'
        },
        widowed(estate) {
            return estate.clientData.profileRelationshipStatus === 'widowed' ||
                estate.clientData.profileRelationshipPreviousStatus === 'widowed'
        },

        divorceSpouseRemarried(estate) {
            return this.divorced(estate);
        },
        divorceConsentOrderSigned(estate) {
            // is divorced and spouse has remarried
            return this.divorced && estate.divorce_spouse_remarried === false;
        },
        divorcePersonName(estate) {
            // is divorced and consent order not signed
            return this.divorced(estate) && estate.divorce_consent_order_signed === false
        },

        separationDivorceProceedingsStarted(estate) {
            return this.separated(estate);
        },
        separationAgreementSigned(estate) {
            return this.separated(estate);
        },
        separationPersonName(estate) {
            return this.separated(estate)
        },

        widowedYearOfDeath(estate) {
            return this.widowed(estate)
        },
        widowedDistributionToOther(estate) {
            return this.widowed(estate)
        },
        widowedDistributionToOtherDetails(estate) {
            return this.widowed(estate) && estate.widowed_distribution_to_other
        },

        // lpa related
        lpaExising(estate) {
            return estate.lpa_existing;
        },
        lpaExistingType(estate) {
            return this.lpaExising(estate);
        },
        lpaChanges(estate) {
            return this.lpaExising(estate);
        },
        lpaChangesDetails(estate) {
            return this.lpaChanges(estate) && estate.lpa_changes;
        },
        // funeral related
        funeralPlanHas(estate) {
            // client is over age
            let age = 50;
            return this.isOverAge(estate.clientData.profileDOB, age);
        },
        funeralPlanFunds(estate) {
            return (
                this.funeralPlanHas(estate) && estate.funeral_plan_has === false
            );
        },
        // will distribution
        willDistributionResidentialProperty(estate) {
            let residentialProperty = estate.clientData.assets.findIndex(
                (asset) => asset.assetType === 'Residential Home'
            );
            return residentialProperty >= 0
        },
        willDistributionResidentialPartner(estate) {
            // must have partner and residential property
            let partner = estate.clientData.people.findIndex(
                (person) => this.isPartner(person)
            );

            return partner >= 0 && this.willDistributionResidentialProperty(estate)
        },
        willDistributionTenantsInCommon(estate) {
            // if not going to partner and property is joint owned
            let residentialProperty = estate.clientData.assets.find(
                (asset) => asset.assetType === 'Residential Home'
            );
            return (
                this.willDistributionResidentialPartner(estate) &&
                estate.will_distribution_residential_partner === false &&
                (residentialProperty.clients.length > 1 || residentialProperty.owner_other)
            );
        },
        willDistributionPartnerResideDetails(estate) {
            // if partner, residential home and not going to partner initially
            return (
                this.willDistributionResidentialPartner(estate) &&
                estate.will_distribution_residential_partner === false
            );
        },
        willDistributionResidentialChildrenAfter(estate) {
            // if have children and residential home
            return (
                !!estate.clientData.people.find((person) => this.isSonOrDaughter(person)) && this.willDistributionResidentialProperty(estate)
            );
        },
        willDistributionEstateToDetails(estate) {
            // if client has a partner, children residential property and not to partner and not to children
            return ((this.willDistributionResidentialChildrenAfter(estate) && estate.will_distribution_residential_children_after === false) &&
                    (this.willDistributionResidentialPartner(estate) && estate.will_distribution_residential_partner === false)) ||
                (!this.willDistributionResidentialChildrenAfter(estate) &&
                    (this.willDistributionResidentialPartner(estate) && estate.will_distribution_residential_partner === false)) ||
                ((this.willDistributionResidentialChildrenAfter(estate) && estate.will_distribution_residential_children_after === false) &&
                    !this.willDistributionResidentialPartner(estate))
        },
        willDistributionOtherAssetsToPartner(estate) {
            // if partner
            return !!estate.clientData.people.find((person) => this.isPartner(person));
        },
        willDistributionOtherAssetsDetails(estate) {
            // if partner and children and other assets not going to partner and not all children included as beneficiaries
            return (
                (!!this.willDistributionOtherAssetsToPartner(estate) &&
                estate.will_distribution_other_assets_to_partner === false) &&
                (!!this.willDistributionResidentialChildrenAfter(estate) &&
                estate.will_distribution_all_children_beneficiaries === false)
            ) ||  (
                (!!this.willDistributionOtherAssetsToPartner(estate) &&
                    estate.will_distribution_other_assets_to_partner === false) &&
                (!this.willDistributionResidentialChildrenAfter(estate))
            ) ||
                (!this.willDistributionOtherAssetsToPartner(estate) &&
                (!!this.willDistributionResidentialChildrenAfter(estate) &&
                    estate.will_distribution_all_children_beneficiaries === false)
            )
        },
        willDistributionAllChildrenBeneficiaries(estate) {
            // if have children
            return (
                !!estate.clientData.people.find((person) =>
                    this.isSonOrDaughter(person)
                )
            );
        },
        willDistributionChildrenExcludedDetails(estate) {
            // if partner, children, other assets going to partner initially and not all children included thereafter
            return (
                this.willDistributionAllChildrenBeneficiaries(estate) &&
                estate.will_distribution_all_children_beneficiaries === false
            );
        },
        willDistributionOtherPropertiesOccupiedFriends(estate) {
            // has properties other than main residential
            return !!estate.clientData.assets.find((asset) =>
                ['Investment Property', 'Second Home'].includes(asset.assetType)
            );
        },
        willDistributionOtherPropertiesOccupiedFriendsDetails(estate) {
            // has properties other than main residential and friends/family live in them
            return (
                this.willDistributionOtherPropertiesOccupiedFriends &&
                estate.will_distribution_other_properties_occupied_friends
            );
        },
        willDistributionOtherPropertiesOccupiedFriendsContinue(estate) {
            // has properties other than main residential and friends/family live in them
            return (
                this.willDistributionOtherPropertiesOccupiedFriends &&
                estate.will_distribution_other_properties_occupied_friends
            );
        },
        willDistributionBeneficiariesIllnessWorking(estate) {
            // shown
            return !!estate;
        },
        willDistributionBeneficiariesIllnessWorkingPeople(estate) {
            // if potential beneficiary may have illness to prevent working the who
            return (
                this.willDistributionBeneficiariesIllnessWorking(estate) &&
                estate.will_distribution_beneficiaries_illness_working
            );
        },
        willDistributionBeneficiariesIllnessWorkingDetails(estate) {
            // if potential beneficiary may have illness to prevent working
            return (
                this.willDistributionBeneficiariesIllnessWorking(estate) &&
                estate.will_distribution_beneficiaries_illness_working
            );
        },
        willDistributionBeneficiariesManagingFinances(estate) {
            // shown
            return !!estate;
        },
        willDistributionBeneficiariesManagingFinancesPeople(estate) {
            // if potential beneficiary may have problems managing their own finances people
            return (
                this.willDistributionBeneficiariesManagingFinances(estate) &&
                estate.will_distribution_beneficiaries_managing_finances
            );
        },
        willDistributionBeneficiariesManagingFinancesDetails(estate) {
            // if potential beneficiary may have problems managing their own finances
            return (
                this.willDistributionBeneficiariesManagingFinances(estate) &&
                estate.will_distribution_beneficiaries_managing_finances
            );
        },
        willDistributionBeneficiariesFinancialVulnerable(estate) {
            // shown
            return !!estate;
        },
        willDistributionBeneficiariesFinancialVulnerablePeople(estate) {
            // if potential beneficiary may be vulnerable with finances people
            return (
                this.willDistributionBeneficiariesFinancialVulnerable(estate) &&
                estate.will_distribution_beneficiaries_financial_vulnerable
            );
        },
        willDistributionBeneficiariesFinancialVulnerableDetails(estate) {
            // if potential beneficiary may be vulnerable with finances
            return (
                this.willDistributionBeneficiariesFinancialVulnerable(estate) &&
                estate.will_distribution_beneficiaries_financial_vulnerable
            );
        },
        willDistributionBeneficiariesStateBenefits(estate) {
            // shown
            return !!estate;
        },
        willDistributionBeneficiariesStateBenefitsPeople(estate) {
            // if potential beneficiary is on means tested state benefits
            return (
                this.willDistributionBeneficiariesStateBenefits(estate) &&
                estate.will_distribution_beneficiaries_state_benefits
            );
        },
        willDistributionBeneficiariesStateBenefitsDetails(estate) {
            // if potential beneficiary is on means tested state benefits
            return (
                this.willDistributionBeneficiariesStateBenefits(estate) &&
                estate.will_distribution_beneficiaries_state_benefits
            );
        },
        willDistributionBeneficiariesDrugs(estate) {
            // shown
            return !!estate;
        },
        willDistributionBeneficiariesDrugsPeople(estate) {
            // if potential beneficiary is on drugs or gambling addiction people
            return (
                this.willDistributionBeneficiariesDrugs(estate) &&
                estate.will_distribution_beneficiaries_drugs
            );
        },
        willDistributionBeneficiariesDrugsDetails(estate) {
            // if potential beneficiary is on drugs or gambling addiction
            return (
                this.willDistributionBeneficiariesDrugs(estate) &&
                estate.will_distribution_beneficiaries_drugs
            );
        },
        willDistributionBeneficiariesDivorced(estate) {
            // shown
            return !!estate;
        },
        willDistributionBeneficiariesDivorcedPeople(estate) {
            // if potential beneficiary is separated or becoming divorced people
            return (
                this.willDistributionBeneficiariesDivorced(estate) &&
                estate.will_distribution_beneficiaries_divorced
            );
        },
        willDistributionBeneficiariesDivorcedDetails(estate) {
            // if potential beneficiary is separated or becoming divorced
            return (
                this.willDistributionBeneficiariesDivorced(estate) &&
                estate.will_distribution_beneficiaries_divorced
            );
        },
        willDistributionEstateTaxable(estate) {
            // shown
            return !!estate;
        },
        // willDistributionIhtMitigate(estate) {
        //     return (
        //         this.willDistributionEstateTaxable(estate) &&
        //         estate.will_distribution_estate_taxable
        //     );
        // },
        willDistributionPensionCollecting(estate) {
            // if over 55 and has a pension
            let pension = estate.clientData.assets.find(
                (asset) => asset.assetType === 'Pensions'
            );
            return (
                this.isOverAge(estate.clientData.profileDOB, 55) && !!pension
            );
        },
        willDistributionPensionNominationDetails(estate) {
            // if over 55 and has a pension and has started collecting pension
            return (
                this.willDistributionPensionCollecting(estate) &&
                estate.will_distribution_pension_collecting
            );
        },
        lifeInsurance(estate) {
            // has a life insurance policy
            return estate.clientData.policies.find((policy) =>
                ['Life Cover', 'Critical Illness', 'Life & Critical Illness'].includes(
                    policy.policyType
                )
            );
        },
        lifeInsuranceAllInTrust(estate) {
            // has a life insurance policy
            return !!this.lifeInsurance(estate);
        },
        lifeInsuranceProfessionallyArranged(estate) {
            // has a life insurance policy
            return !!this.lifeInsurance(estate);
        },
        lifeInsuranceArrangedDetails(estate) {
            // has life insurance policy and not arranged by a professional insurance specialist
            return (
                !!this.lifeInsurance(estate) &&
                estate.life_insurance_professionally_arranged === false
            );
        },
        lifeInsuranceNone(estate) {
            // has no life insurance policies
            return !this.lifeInsurance(estate);
        },
        lifeInsuranceReviewedRecently(estate) {
            // has a life insurance policy
            return !!this.lifeInsurance(estate);
        }
    }
};
