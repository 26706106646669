<template>
  <b-card body-class="p-0" class="trans-card" >
    <div class="d-flex justify-content-between align-items-center p-3">
      <div class="d-flex align-items-center">
        <div class="img-wrapper pr-3">
          <img alt="cardImage" :src="image" />
        </div>
        <div class="d-flex align-items-start flex-column">
          <h5>{{ clientNameList({clients: policy.clients, firstName: true}) }}</h5>
          <template v-if="policy.policy_recommended">
            <h6 class="mb-2 text-mute">{{ policy.policy_recommended.type }}</h6>
            <h6 class="mb-0 text-mute">{{ policy.policy_recommended.provider }}</h6>
          </template>
        </div>
      </div>
    </div>
    <div class="bg-gray-100 border-top border-gray-200">
      <b-progress-bar :value="status[2]" variant="primary"></b-progress-bar>
    </div>
    <div
        class="
        d-flex
        align-items-center
        justify-content-start
        pl-3
        pr-1
        pt-8
        pb-8
      "
    >
      <div class="badge text-10 p-1" :class="status[1]">{{ status[0] }}</div>
    </div>
  </b-card>
</template>

<script>
import { clientHelpers } from "@/mixins/clientHelpers";

export default {
  name: "PolicyCard",
  mixins: [clientHelpers],
  props: {
    policy: {
      type: Object,
      required: true,
    },
  },
  computed: {
    status() {
      // return status text and badge colour
      let string = this.policy.STATUS_CHOICES.find(
          (option) => option[0] === this.policy.status
      )[1];
      let progress = 20;
      let variant = "badge-primary";

      if (this.policy.status === 'awaiting_log_submit') {
        variant = "badge-primary";
        progress = 20;
      }
      else if (this.policy.status === 'awaiting_terms') {
        variant = "badge-primary";
        progress = 40;
      }
      else if (this.policy.status === 'awaiting_on_risk') {
        variant = "badge-primary";
        progress = 60;
      }
      else if (this.policy.status === 'awaiting_commission') {
        variant = "badge-primary";
        progress = 80;
      }
      else if (this.policy.status.includes('end')) {
        variant = "badge-success";
        progress = 100;
      }

      return [string, variant, progress];
    },
    image() {
      // image for card
      if (this.policy.clients.length > 1) {
        return require("@/assets/images/group.svg");
      }
      else if (this.policy.clients.length === 1 && this.policy.clients[0].profileSex === 'male') {
        return require("@/assets/images/man-profile.svg");
      }
      return require("@/assets/images/woman-profile.svg");
    },
  },
};
</script>
