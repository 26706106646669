<template>
  <div>
    <div class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num"><img :src="require('@/assets/images/padlock.svg')"
                                 alt="padlock"
                                 class="trans-section-locked-icon">
      </div>
      <h4 class="trans-section-title">Documents</h4>
    </div>

    <div>
      <DocumentSection
          ref="documents"
          title="This section contains any documents produced and supporting paperwork."
          :locked="locked"
          :transaction="transaction">
      </DocumentSection>
    </div>
  </div>
</template>

<script>
import DocumentSection from "@/components/common/documents/DocumentSection";

export default {
  name: 'Documents',
  components: {DocumentSection},
  props: {
    transaction: {
      type: Object,
      required: true
    },
    locked: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    documentsFetch() {
      this.$refs.documents.documentsFetch()
    }
  }
}
</script>
