<template>
  <b-col md="7">
    <div class="section-title">
      <h4 class="pl-0 pr-3 pt-3 pb-3">
        <span class="pl-0">Introduction</span>
      </h4>
    </div>
    <p class="pr-20" style="margin-top: -30px"  >
      The insurance review process begins with gathering detailed information
      and instructions.
    </p>
  </b-col>
</template>

<script>
export default {
  name: "Introduction",
};
</script>
