<template>
  <div v-if="transaction">
    <div class="hero-wrapper">
      <div class="hero trans-hero">
        <img
            alt="shield"
            class="trans-background"
            src="@/assets/images/trans-banner.png"
        />
        <div class="container">
          <h1 class="text-white">Mortgage Progress</h1>
          <h3 class="arrow-before text-white pl-30">Transaction<br/></h3>
        </div>
      </div>
    </div>

    <Header :transaction="transaction"/>

    <div class="border-bottom-gray-200 pb-40 mt-12">
      <b-container>
        <b-row>
          <Introduction/>
        </b-row>
      </b-container>
    </div>

    <div class="border-gray-200 border-wide-bottom">
      <b-container>
        <div class="border-wide-left border-gray-200 pl-32 pb-8">
          <MortgageSurvey
              :progress="mortgageSurveyProgress"
              :transaction="transaction"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <ClientProfiles
              :client-profile-progress="clientProfileProgress"
              :transaction="transaction"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <MortgagePropertySurvey
              :progress="mortgagePropertyProgress"
              :transaction="transaction"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <Products
              :mortgages="transaction.mortgages"
              :transaction="transaction"
              :previousSectionComplete="false"
          />
        </div>
      </b-container>
    </div>
    <div class="border-bottom-gray-200 pb-40 pt-40 mb-100">
      <b-container>
        <b-row>
          <b-col md="3">
            <b-card class="mt-48 pt-2 pl-2 sticky-top">
              <h4 class="mb-30">Need Support?</h4>
              <p>
                <a class="d-flex justify-items-center" href="#">
                  <i class="i-Telephone pr-2  text-secondary text-20"/>
                  0330 1757504
                </a>
              </p>

              <p>
                <a class="d-flex justify-items-center" href="https://dm-legal.co.uk" target="_blank">
                  <i class="i-Internet pr-2 text-secondary text-20"/>
                  dm.mortgage
                </a>
              </p>

              <p>
                <a class="d-flex justify-items-center" href="mailto:enquiries@dunham-mccarthy.co.uk">
                  <i class="i-Mail pr-2 text-secondary text-20"/>
                  info@dm.mortgage
                </a>
              </p>
            </b-card>
          </b-col>
          <b-col md="8" offset-md="1">
            <Appointments
                ref="appointments"
                :numbered="false"
                :transaction="transaction"
                :showBookingButton="noFutureAppointments"
                help-text="Below are details of any appointments, past and present. From here you can reschedule an appointment if necessary. To cancel an appointment without rescheduling please contact 01785 336222."
                appointment-type="Mortgage Consultation"
                host-role="Mortgage Introducer"
                @refresh="$emit('refreshTransaction', $event)"
            />

            <DocumentRequestSection
                v-model="transaction"
                title="Below are details of documents requested by your mortgage advisor."
            />
            <Documents :transaction="transaction"/>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <Contact
        class="mt-80 mb-80"
        :transaction="transaction"
        @refreshTransaction="$emit('refreshTransaction', $event)"
    />
  </div>
</template>

<script>
import {progressMortgageHelpers} from "@/views/introducer/transaction/detail/mortgage/progressMortgageHelpers";

import Header from "./Header";
import Introduction from "./Introduction";
import ClientProfiles from "./ClientProfiles";
import MortgagePropertySurvey from "./MortgagePropertySurvey";
import MortgageSurvey from "./MortgageSurvey";
import Appointments from "../../../../../components/common/appointment";
import Contact from "../../../../../components/common/contact/Contact";
import DocumentRequestSection from "../../../../../components/common/documents/requests/DocumentRequestSection";
import Documents from "@/views/client/transaction/detail/mortgage/Documents";
import Products from "@/views/client/transaction/detail/mortgage/Products";

export default {
  name: "TransactionMortgageDetail",
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  mixins: [progressMortgageHelpers],
  components: {
    Products,
    Documents,
    DocumentRequestSection,
    Contact,
    Appointments,
    MortgageSurvey,
    MortgagePropertySurvey,
    Header,
    Introduction,
    ClientProfiles,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    noFutureAppointments() {
      return !this.transaction.events.filter(event => event.status === 'Scheduled' && new Date(event.endDateTime) > new Date()).length > 0
    }
  }
};
</script>
