<template>
  <b-overlay :show="disabled" opacity="0.5" >
    <template #overlay>-</template>
    <b-card
        body-class="p-0" class="hover-down trans-card"  @click="goto">
      <div class="d-flex justify-content-between align-items-center p-3">
        <div class="d-flex align-items-center">
          <div class="img-wrapper pr-3">
            <img alt="cardImage" :src="image" />
          </div>
          <div class="d-flex align-items-start flex-column">
            <h5 class="">{{ clientNameFirstLast(will.client) }}</h5>
            <h6 class="mb-0 text-mute">Last Will & Testament</h6>
          </div>
        </div>

        <div @click.stop="goto" class="cursor-pointer pr-2">
          <i class="text-25 text-secondary i-Next1"></i>
        </div>
      </div>
      <div class="bg-gray-100 border-top border-gray-200">
        <b-progress-bar :value="status[2]" variant="primary"></b-progress-bar>
      </div>
      <div
          class="
        d-flex
        align-items-center
        justify-content-start
        pl-3
        pr-1
        pt-8
        pb-8
      "
      >
        <div class="badge text-10 p-1" :class="status[1]">{{ status[0] }}</div>

      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { clientHelpers } from "@/mixins/clientHelpers";

export default {
  name: "WillCard",
  mixins: [clientHelpers],
  props: {
    will: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goto() {
      this.$router.push({
        name: "ClientEstatePlanningWillInstructions",
        params: {
          transactionId: this.will.transaction,
          clientId: this.will.client.id,
          willId: this.will.id,
        },
      }).catch(
          error => {
            console.log(error)
          }
      )
    },
  },
  computed: {
    status() {
      // return status text and badge colour
      let string = this.will.STATUS_CHOICES.find(
        (option) => option[0] === this.will.status
      )[1];
      let progress = 20;
      let variant = "badge-primary";

      if (this.will.status !== "awaiting_instructions") {
        variant = "badge-success";
        progress = 100;
      }

      return [string, variant, progress];
    },
    image() {
      // image for card
      if (this.will.client.profileSex === "male") {
        return require("@/assets/images/man-profile.svg");
      }
      return require("@/assets/images/woman-profile.svg");
    },
    disabled () {
      return this.will.status !== 'awaiting_instructions'
    }
  },
};
</script>
