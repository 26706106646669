import {clientHelpers} from "@/mixins/clientHelpers";

export const progressMortgageHelpers = {
    mixins: [clientHelpers],
    computed: {
        // transaction clients ordered and simplified
        clients() {
            return this.transaction.clients.sort((a, b) => a.id - b.id)
        },
        // progress
        clientProfileProgress() {
            // return percentage complete for each client profile
            let result = []
            let tF = [true, false]

            // guards
            if (!this.transaction) return result

            for (let i in this.clients) {
                // init variable
                let completed = 0
                let questions = 0 // amount of required questions
                let percentage = 0
                let errors = []
                let client = this.clients[i]

                // add questions and percentages
                questions += 1
                if (client.profileNameTitle) completed += 1
                else errors.push('Title missing')

                questions += 1
                if (client.profileNameFirst) completed += 1
                else errors.push('First name missing')

                questions += 1
                if (client.profileNameLast) completed += 1
                else errors.push('Last name missing')

                questions += 1
                if (tF.includes(client.profilePreferredNameYN)) completed += 1
                else errors.push('Preferred name missing')

                if (client.profilePreferredNameYN) {
                    questions += 1
                    if (client.profilePreferredName) {
                        completed += 1
                    } else errors.push('Preferred name detail missing')
                }

                questions += 1
                if (tF.includes(client.profilePreviousNameYN)) completed += 1
                else errors.push('Previous name missing')

                if (client.profilePreviousNameYN) {
                    // skipp-able questions (may want commenting out)
                    questions += 3
                    if (client.profilePreviousName) {
                        completed += 1
                    } else errors.push('Previous name details missing')
                    if (client.profilePreviousNameChangedDate) {
                        completed += 1
                    } else errors.push('Previous name date missing')
                    if (client.profilePreviousNameChangedMethod) {
                        completed += 1
                    } else errors.push('Previous name method missing')
                }

                questions += 1
                if (client.profileSex) completed += 1
                else errors.push('Gender missing')

                questions += 1
                if (client.profileDOB) completed += 1
                else errors.push('Date of birth missing')

                questions += 1
                if (client.profileNationality) completed += 1
                else errors.push('Nationality missing')

                questions += 1
                if (tF.includes(client.profileDiplomaticImmunity)) completed += 1
                else errors.push('Diplomatic status missing')

                questions += 1
                if (client.profileRelationshipStatus) completed += 1
                else errors.push('Relationship status missing')

                questions += 1
                if (tF.includes(client.profileRelationshipCurrentlyYN)) {
                    completed += 1

                    if (client.profileRelationshipCurrentlyYN) {
                        questions += 1
                        if (client.profileRelationshipCurrentStatus) completed += 1
                        else errors.push('Client current relationship status missing')

                        questions += 1
                        if (client.profileRelationshipDuration) completed += 1
                        else errors.push('Client Relationship duration missing')
                    }

                } else errors.push('Currently in a relationship missing')

                if (client.profileRelationshipCurrentlyYN === false) {
                    questions += 1
                    if (tF.includes(client.profileRelationshipPreviousYN)) {
                        completed += 1

                        if (client.profileRelationshipPreviousYN) {
                            questions += 1
                            if (client.profileRelationshipPreviousStatus) completed += 1
                            else errors.push('Previous relationship ending missing')
                        }
                    } else errors.push('Been in a previous marriage missing')
                }

                // if (client.profileMobileNumber) completed += 1
                // else errors.push('Mobile number missing')
                //
                // if (client.profileEmail) completed += 1
                // else errors.push('Email missing')

                // question not included in mortgage client profile questionnaire
                // questions += 1
                // if (tF.includes(client.profileMarketingConsent)) completed += 1
                // else errors.push('Marketing consent missing')

                questions += 1
                if (client.current_address) completed += 1
                else errors.push('Current address is missing')

                questions += 1
                if (client.profileAddressMovedIn) completed += 1
                else errors.push('Address moved in date missing')

                questions += 1
                if (client.profileAddressOccupancy) completed += 1
                else errors.push('Address occupancy missing')

                questions += 1
                if (tF.includes(client.profileAddressCorrespondenceYN)) completed += 1
                else errors.push('Address correspondence missing')

                let now = new Date()
                let addressHistoryYearsRequired = 5
                if (client.profileAddressMovedIn &&
                    now.setFullYear(now.getFullYear() - addressHistoryYearsRequired) <
                    new Date(client.profileAddressMovedIn)) {
                    questions += 1
                    if (client.previous_address && client.previous_address.length) completed += 1
                    else errors.push('Previous address history missing')
                }

                questions += 1
                if (tF.includes(client.children_have)) completed += 1
                else errors.push('Have children missing')

                if (client.children_have) {
                    questions += 1
                    if (client.people && client.people.filter(p => this.isSonOrDaughter(p)).length) completed += 1
                    else errors.push('Children details missing')
                }

                questions += 1
                if (tF.includes(client.profilePregnant)) completed += 1
                else errors.push('Expecting a child missing')

                if (client.profilePregnant) {
                    questions += 1
                    if (client.profilePregnantDueDate) {
                        completed += 1
                    } else errors.push('Expecting a child date missing')
                }

                questions += 1
                if (tF.includes(client.dependants_have)) completed += 1
                else errors.push('Client other dependants missing')

                if (client.dependants_have) {
                    questions += 1
                    if (client.people && client.people.filter(p => this.isDependant(p)).length) completed += 1
                    else errors.push('Dependants details missing')
                }

                questions += 1
                if (tF.includes(client.assets_other_high_value)) completed += 1
                else errors.push('Other high value assets missing')

                if (client.assets_other_high_value) {
                    questions += 1
                    if (client.assets && client.assets.filter(a => !this.assetsOtherNot.includes(a.assetType)).length) completed += 1
                    else errors.push('Other asset details missing')
                }
                // not included in mortgage client profile questionnaire
                // questions += 1
                // if (tF.includes(client.policies_other_type)) completed += 1
                // else errors.push('Other policies missing')

                if (client.policies_other_type) {
                    questions += 1
                    if (client.policies && client.policies.filter(p => !this.policiesOthersNot.includes(p.policyType)).length) completed += 1
                    else errors.push('Other policies details missing')
                }

                questions += 1
                if (tF.includes(client.mortgage_debts)) completed += 1
                else errors.push('Mortgage debts missing')

                if (client.mortgage_debts) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => this.liabilitiesMortgage.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Mortgage details missing')
                }

                questions += 1
                if (tF.includes(client.unsecured_loans)) completed += 1
                else errors.push('Unsecured loans missing')

                if (client.unsecured_loans) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => this.liabilitiesLoans.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Unsecured loan details missing')
                }

                questions += 1
                if (tF.includes(client.credit_card_balances)) completed += 1
                else errors.push('Credit cards missing')

                if (client.credit_card_balances) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => this.liabilitiesCreditCards.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Credit card details missing')
                }

                questions += 1
                if (tF.includes(client.other_credit_agreements)) completed += 1
                else errors.push('Other credit agreements missing')

                if (client.other_credit_agreements) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => !this.liabilitiesOtherNot.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Other credit agreements details missing')
                }

                questions += 1
                if (tF.includes(client.profileBankruptcyIVAYN)) completed += 1
                else errors.push('Bankruptcy missing')

                questions += 1
                if (tF.includes(client.profileDebtManagementYN)) completed += 1
                else errors.push('Debt management missing')

                questions += 1
                if (tF.includes(client.profileCCJYN)) completed += 1
                else errors.push('CCJ missing')

                questions += 1
                if (tF.includes(client.profileMissedYN)) completed += 1
                else errors.push('Missed payment missing')

                questions += 1
                if (client.commitments && client.commitments.length) completed += 1
                else errors.push('Commitments details missing')

                // calculate percentage
                percentage = Number(Number((completed / questions) * 100).toFixed(0))

                // add percentage to return array
                result.push({percentage: percentage, errors: errors, client: client})
            }
            return result
        },
        mortgageSurveyProgress() {
            // mortgage survey percentage

            // guards
            if (!this.transaction.mortgage_survey) return null

            let required = 0;
            let complete = 0;
            let errors = [];
            let survey = this.transaction.mortgage_survey

            // Type
            required += 1
            if (survey.type !== null) {
                complete += 1;
            } else {
                errors.push('Type missing');
            }

            if (survey.type === 'Purchase') {
                required += 1
                if (survey.new_usage !== null) {
                    complete += 1
                    if (!['Second Home'].includes(survey.new_usage)) {
                        required += 1
                        if (survey.ftb !== null) {
                            complete += 1
                        } else {
                            errors.push('First time buyer missing')
                        }
                    }
                } else {
                    errors.push('Usage missing')
                }

                required += 1
                if (survey.new_self_build !== null) {
                    complete += 1
                } else {
                    errors.push('Self build missing')
                }

                required += 1
                if (survey.new_rtb !== null) {
                    complete += 1
                    if (survey.new_rtb) {
                        required += 1
                        if (survey.new_rtb_discount !== null) {
                            complete += 1
                        } else {
                            errors.push('Right to buy discounted missing')
                        }
                    }

                } else {
                    errors.push('Right to buy missing')
                }

                required += 1
                if (survey.new_property_found !== null) {
                    complete += 1
                    if (['Still Looking'].includes(survey.new_property_found)) {
                        required += 1
                        if (survey.new_timescale !== null) {
                            complete += 1
                        } else {
                            errors.push('Time-scale missing')
                        }
                    }

                    required += 1
                    if (survey.new_value !== null) {
                        complete += 1
                    } else {
                        errors.push('Property value missing')
                    }

                    required += 1
                    if (survey.new_balance !== null) {
                        complete += 1
                    } else {
                        errors.push('Balance missing')
                    }

                    if (['Buy to Let', 'Holiday Let'].includes(survey.new_usage)) {
                        required += 1
                        if (survey.rent !== null) {
                            complete += 1
                        } else {
                            errors.push('Rent commanded missing')
                        }
                    }

                } else {
                    errors.push('Property found missing')
                }

                if (['Residential'].includes(survey.new_usage)) {
                    required += 1
                    if (survey.new_equity_loan !== null) {
                        complete += 1
                    } else {
                        errors.push('Equality loan missing')
                    }
                }

                if (['Other Scheme', 'Help to Buy'].includes(survey.new_equity_loan)) {
                    required += 1
                    if (survey.new_equity_loan_percentage !== null) {
                        complete += 1
                    } else {
                        errors.push('Equality loan percentage missing')
                    }
                }

                if (['Residential'].includes(survey.new_usage) && !['Help to Buy', 'Other Scheme'].includes(survey.new_equity_loan)) {
                    required += 1
                    if (survey.new_shared !== null) {
                        complete += 1
                    } else {
                        errors.push('Shared ownership missing')
                    }
                }

                if (survey.new_shared && survey.new_usage === 'Residential') {
                    required += 1
                    if (survey.new_shared_percentage !== null) {
                        complete += 1
                    } else {
                        errors.push('Shared ownership percentage missing')
                    }
                    required += 1
                    if (survey.new_shared_rent !== null) {
                        complete += 1
                    } else {
                        errors.push('Shared rent percentage missing')
                    }
                }

                if (survey.ftb === false && survey.new_usage === 'Residential') {
                    required += 1
                    if (survey.has_existing_property !== null) {
                        complete += 1
                    } else {
                        errors.push('Existing property missing')
                    }
                }

                if (survey.has_existing_property && survey.ftb === false && ['Residential'].includes(survey.new_usage)) {
                    required += 1
                    if (survey.existing_address !== null) {
                        complete += 1
                    } else {
                        errors.push('Existing address missing')
                    }
                    required += 1
                    if (survey.existing_owner !== null) {
                        complete += 1
                    } else {
                        errors.push('Existing owner missing')
                    }
                    required += 1
                    if (survey.existing_value !== null) {
                        complete += 1
                    } else {
                        errors.push('Existing value missing')
                    }
                    required += 1
                    if (survey.existing_status !== null) {
                        complete += 1
                    } else {
                        errors.push('Existing status missing')
                    }
                    required += 1
                    if (survey.existing_encumbered !== null) {
                        complete += 1
                        if (survey.existing_encumbered) {
                            required += 1
                            if (survey.existing_balance !== null) {
                                complete += 1
                            } else {
                                errors.push('Existing balance missing')
                            }
                            required += 1
                            if (survey.existing_lender !== null) {
                                complete += 1
                            } else {
                                errors.push('Existing lender missing')
                            }
                        }
                    } else {
                        errors.push('Existing encumbered missing')
                    }

                    required += 1
                    if (survey.existing_equity_loan !== null) {
                        complete += 1
                        if (['Other Scheme', 'Help to Buy'].includes(survey.existing_equity_loan)) {
                            required += 1
                            if (survey.existing_equity_loan_percentage !== null) {
                                complete += 1
                            } else {
                                errors.push('Existing equity loan percentage missing')
                            }
                        }

                        if (survey.existing_equity_loan === 'No') {
                            required += 1
                            if (survey.existing_shared !== null) {
                                complete += 1
                            } else {
                                errors.push('Existing shared ownership missing')
                            }
                            if (survey.existing_shared) {
                                required += 1
                                if (survey.existing_shared_percentage !== null) {
                                    complete += 1
                                } else {
                                    errors.push('Existing shared percentage missing')
                                }
                                required += 1
                                if (survey.existing_shared_rent !== null) {
                                    complete += 1
                                } else {
                                    errors.push('Existing shared rent missing')
                                }
                            }
                        }
                    } else {
                        errors.push('Existing equity loan missing')
                    }
                }

                required += 1
                if (survey.new_deposit !== null && survey.new_deposit.length) {
                    complete += 1
                    if (survey.new_deposit.includes('Gift (Family)')) {
                        required += 1
                        if (survey.new_deposit_gift_family_details) {
                            complete += 1
                        } else errors.push('Deposit family source details missing')
                    }
                    if (survey.new_deposit.includes('Gift (Other)')) {
                        required += 1
                        if (survey.new_deposit_gift_other_details) {
                            complete += 1
                        } else errors.push('Deposit other source details missing')
                    }
                } else {
                    errors.push('Deposit missing')
                }

                required += 1
                if (survey.savings !== null) {
                    complete += 1
                } else {
                    errors.push('Saving missing')
                }


            } else if (['Remortgage', 'Product Transfer'].includes(survey.type)) {

                required += 1
                if (survey.existing_address !== null) {
                    complete += 1
                } else {
                    errors.push('Existing address missing')
                }

                required += 1
                if (survey.existing_owner !== null) {
                    complete += 1
                } else {
                    errors.push('Existing owner missing')
                }

                required += 1
                if (survey.new_usage !== null) {
                    complete += 1
                } else {
                    errors.push('Usage missing')
                }

                required += 1
                if (survey.new_value !== null) {
                    complete += 1
                } else {
                    errors.push('Value missing')
                }

                if (['Buy to Let', 'Holiday Let'].includes(survey.new_usage)) {
                    required += 1
                    if (survey.rent !== null) {
                        complete += 1
                    } else {
                        errors.push('Rent missing')
                    }
                }

                required += 1
                if (survey.existing_encumbered !== null) {
                    complete += 1
                    if (survey.existing_encumbered) {
                        required += 1
                        if (survey.existing_balance !== null) {
                            complete += 1
                        } else {
                            errors.push('Existing balance missing')
                        }
                        required += 1
                        if (survey.existing_lender !== null) {
                            complete += 1
                        } else {
                            errors.push('Existing lender missing')
                        }
                    }
                } else {
                    errors.push('Encumbered missing')
                }

                required += 1
                if (survey.new_balance !== null) {
                    complete += 1
                    if (survey.existing_balance && survey.new_balance > survey.existing_balance) {
                        required += 1
                        if (survey.existing_capital_raising_reason !== null) {
                            complete += 1
                        } else {
                            errors.push('Existing capital raising reason missing')
                        }
                    }
                } else {
                    errors.push('Balance missing')
                }

                required += 1
                if (survey.existing_equity_loan !== null) {
                    complete += 1
                    if (['Other Scheme', 'Help to Buy'].includes(survey.existing_equity_loan)) {
                        required += 1
                        if (survey.existing_equity_loan_percentage !== null) {
                            complete += 1
                        } else {
                            errors.push('Existing equality loan percentage missing')
                        }
                        required += 1
                        if (survey.new_equity_loan !== null) {
                            complete += 1
                            if (['Help to Buy', 'Other Scheme'].includes(survey.new_equity_loan)) {
                                required += 1
                                if (survey.new_equity_loan_percentage !== null) {
                                    complete += 1
                                } else {
                                    errors.push('New equality loan percentage missing')
                                }
                            }


                        } else {
                            errors.push('New equality loan missing')
                        }
                    }
                } else {
                    errors.push('Existing equity loan missing')
                }


                if (survey.existing_equity_loan === 'No') {
                    required += 1

                    if (survey.existing_shared !== null) {
                        complete += 1
                        required += 1
                        if (survey.existing_shared_percentage !== null) {
                            complete += 1
                        } else {
                            errors.push('Existing shared percentage missing')
                        }
                        required += 1
                        if (survey.existing_shared_rent !== null) {
                            complete += 1
                        } else {
                            errors.push('Existing shared rent missing')
                        }
                        required += 1
                        if (survey.new_shared !== null) {
                            complete += 1
                            if (survey.new_shared) {
                                required += 1
                                if (survey.new_shared_percentage !== null) {
                                    complete += 1
                                } else {
                                    errors.push('New shared percentage missing')
                                }
                                required += 1
                                if (survey.new_shared_rent !== null) {
                                    complete += 1
                                } else {
                                    errors.push('New shared rent missing')
                                }
                            }
                        } else {
                            errors.push('New shared ownership missing')
                        }


                    } else {
                        errors.push('Existing shared ownership missing')
                    }
                }
            }

            // end
            this.$set(
                survey,
                'mortgageQuestionsComplete',
                required <= complete
            );
            return {
                percentage: Number(Number((complete / required) * 100).toFixed(0)),
                errors: errors
            };

        },
        mortgagePropertyProgress() {
            // property survey percentage

            // guards
            if (!this.transaction.mortgage_survey.property) return null

            let required = 0;
            let complete = 0;
            let errors = [];
            let property = this.transaction.mortgage_survey.property

            // GENERAL TAB
            // Address
            required += 1
            if (property.address) {
                complete += 1;
            } else errors.push('Address missing');

            // Type
            required += 1
            if (property.type) {
                complete += 1;
                if (['House', 'Flat', 'Maisonette'].includes(property.type)) {
                    required += 1;
                    // Description
                    if (property.description) {
                        complete += 1;
                    } else errors.push('Description missing');
                }
            } else errors.push('Type missing');

            // Tenure
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.tenure) {
            //     complete += 1;
            //     if (property.tenure === 'Leasehold') {
            //         required += 3;
            //         //Lease Remaining
            //         if (property.lease_end_year) {
            //             complete += 1;
            //         } else errors.push('Lease Remaining missing');
            //
            //         //Service Charge p/a
            //         if (property.service_charge) {
            //             complete += 1;
            //         } else errors.push('Service Charge p/a missing');
            //
            //         //Ground Rent p/a
            //         if (property.ground_rent) {
            //             complete += 1;
            //         } else errors.push('Ground Rent p/a missing');
            //     }
            // } else errors.push('Tenure missing');

            // Usage
            required += 1;
            if (property.usage) {
                complete += 1;
                if (property.usage === 'Mainly Residential') {
                    required += 1;
                    //Percentage Residential
                    if (property.usage_mainly_residential) {
                        complete += 1;
                    } else errors.push('Percentage Residential missing');

                }
                if (property.usage === 'Buy-to-Let' || property.usage === 'HMO' || property.usage === 'Mixed') {
                    required += 1;
                    //Monthly Rent
                    if (property.rent) {
                        complete += 1;
                    } else errors.push('Monthly Rent missing');

                    if (property.usage === 'HMO') {
                        required += 1;
                        //HMO Licence Required
                        if (property.usage_hmo != null) {
                            complete += 1;
                        } else errors.push('HMO Licence Required missing');
                    }

                    if (property.usage === 'Mixed') {
                        required += 1;
                        //Usage Details
                        if (property.usage_mixed != null) {
                            complete += 1;
                        } else errors.push('Usage Details missing');
                    }
                }
            } else errors.push('Usage missing');

            // SPECIFICATION TAB
            // New Property
            required += 1;
            if (property.new !== null) {
                complete += 1;
                if (property.new === true) {
                    required += 2;
                    // Under Construction
                    if (property.under_construction !== null) {
                        complete += 1;
                    } else errors.push('Under Construction missing');

                    // Has NHBC or Equivalent
                    if (property.nhbc !== null) {
                        complete += 1;
                    } else errors.push('Has NHBC or Equivalent missing');

                }
            } else errors.push('New Property missing');

            // Bedrooms
            required += 1;
            if (property.bedrooms) {
                complete += 1;
            } else errors.push('Bedrooms missing');

            // Reception Rooms
            required += 1;
            if (property.receptions) {
                complete += 1;
            } else errors.push('Reception Rooms missing');

            // Bathrooms
            required += 1;
            if (property.bathrooms) {
                complete += 1;
            } else errors.push('Bathrooms missing');

            // Separate WC
            required += 1;
            if (property.wc !== null) {
                complete += 1;
            } else errors.push('Separate WC missing');

            // Has Garage
            required += 1;
            if (property.garage !== null) {
                complete += 1;
            } else errors.push('Has Garage missing');

            // Has Parking
            required += 1;
            if (property.parking !== null) {
                complete += 1;
            } else errors.push('Has Parking missing');

            // Has Cellar
            required += 1;
            if (property.cellar !== null) {
                complete += 1;
            } else errors.push('Has Cellar missing');

            // Has 1 Acre+
            required += 1;
            if (property.land !== null) {
                complete += 1;
            } else errors.push('Has 1 Acre+ missing');

            // CONSIDERATIONS TAB
            // Non-Standard Construction
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.non_standard_construction !== null) {
            //     complete += 1;
            //     if (property.non_standard_construction === true) {
            //         required += 1;
            //         //Construction Type
            //         if (property.construction_type) {
            //             complete += 1;
            //         } else errors.push('Construction Type missing');
            //     }
            // } else errors.push('Non-Standard Construction missing');

            // Near Commercial
            required += 1;
            if (property.near_commercial !== null) {
                complete += 1;
            } else errors.push('Near Commercial missing');

            // Past Flooding
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.flooded !== null) {
            //     complete += 1;
            // } else errors.push('Past Flooding missing');

            // Coastal Erosion
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.corrosion !== null) {
            //     complete += 1;
            // } else errors.push('Coastal Erosion missing');

            // Past Subsidence
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.subsidence !== null) {
            //     complete += 1;
            // } else errors.push('Past Subsidence missing');

            // Habitable
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.habitable !== null) {
            //     complete += 1;
            // } else errors.push('Habitable missing');

            // Listed Building
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.listed !== null) {
            //     complete += 1;
            // } else errors.push('Listed Building missing');

            // Agricultural Restrictions
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.restrictions !== null) {
            //     complete += 1;
            // } else errors.push('Agricultural Restrictions missing');

            // Ex Local Authority
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.authority !== null) {
            //     complete += 1;
            // } else errors.push('Ex Local Authority missing');

            // ACCESS TAB
            // Access Provider
            required += 1;
            if (property.access) {
                complete += 1;
                if (['Estate Agent', 'Letting Agent'].includes(property.access)) {
                    required += 1;
                    // Agent Address
                    if (property.access_name_address) {
                        complete += 1;
                    } else errors.push('Agent Address missing');

                }
            } else errors.push('Access Provider missing');

            // Access Name
            required += 1;
            if (property.access_name) {
                complete += 1;
            } else errors.push('Access Name missing');

            // Access Phone
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.access_phone) {
            //     complete += 1;
            // } else errors.push('Access Phone missing');

            // Access Email
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.access_email) {
            //     complete += 1;
            // } else errors.push('Access Email missing');

            //FINANCIALS TAB
            // Agreed Price
            // SKIPP-ABLE SO NOT COUNTED
            // required += 1;
            // if (property.price) {
            //     complete += 1;
            // } else errors.push('Agreed Price missing');

            // Deadline
            required += 1;
            if (property.deadline) {
                complete += 1;
                if (['28 Days', 'Specific Date'].includes(property.deadline)) {
                    required += 1;
                    //Deadline Date
                    if (property.deadline_date) {
                        complete += 1;
                    } else errors.push('Deadline Date missing');
                }
            } else errors.push('Deadline missing');

            // Vendor Type
            required += 1;
            if (property.vendor) {
                complete += 1;
            } else errors.push('Vendor Type missing');

            // Concessionary Details
            required += 1;
            if (property.concessionary !== null) {
                complete += 1;

                if (property.concessionary) {
                    required += 1
                    if (property.concessionary_details) {
                        complete += 1;
                    } else errors.push('Concessionary Details missing');
                }

            } else errors.push('Concessionary question not answered')

            // Incentives Details
            required += 1;
            if (property.incentives !== null) {
                complete += 1;
                if (property.incentives) {
                    required +=1
                    if (property.incentives_details) {
                        complete += 1;
                    } else errors.push('Incentives Details missing');
                }

            } else errors.push('Incentive question not answered')

            // end
            return {
                percentage: Number(Number((complete / required) * 100).toFixed(0)),
                errors: errors
            };
        },
        clientProfilesSectionComplete() {
            return this.clientProfileProgress.filter(
                c => c.percentage === 100).length === this.clientProfileProgress.length
        },
        mortgageSurveyComplete() {
            return this.mortgageSurveyProgress.percentage === 100
        },
        mortgagePropertyComplete() {
          return this.mortgagePropertyProgress.percentage === 100
        },
        currentHandoverStep() {
            if (this.hasHandedOver) return ''
            if (!this.clientProfilesSectionComplete) return 'before_client_profile'
            else if (this.clientProfilesSectionComplete) return 'after_client_profile'
            return 'before_client_profile'
        },
        hasHandedOver() {
            return !!this.transaction.handover_point;
        }
    }
}
