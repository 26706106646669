<template>
  <div>
      <div class="mb-30">
        <p>{{ title }}</p>
        <p v-if="!documents.length">No related documents found.</p>
      </div>
    <b-row>
      <b-col
          v-for="(document, index) in documents"
          :key="index + 'doc'"
          lg="4"
          md="6"
      >
        <DocumentCard
            :data="document"
            :disable-send-button="false"
            :select-mode="false"
            :locked="locked"
            @click="selectDocument(document)"
            @delete="deleteDocumentConfirm(document.id)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {documentHelpers} from "./documentHelpers";
import DocumentCard from "./DocumentCard";

export default {
  name: 'DocumentSection',
  components: {DocumentCard},
  mixins: [documentHelpers],
  props: {
    transaction: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    locked: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
   this.documentsFetch()
  },
  data() {
    return {
      documentsData: []
    }
  }
}
</script>
