import {clientHelpers} from "@/mixins/clientHelpers";
import {dateFormat} from "@/mixins/dateFormat";
import {showClientQuestionHelpers} from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";

export const progressEstatePlanningHelpers = {
    mixins: [clientHelpers, dateFormat, showClientQuestionHelpers],
    computed: {
        // transaction ordered and simplified
        clients() {
            return this.transaction.clients.sort((a, b) => a.id - b.id)
        },
        estatePlanningSurveys() {
            return this.transaction.estate_planning_surveys.sort((a, b) => a.client - b.client)
        },
        wills() {
            return this.transaction.wills.sort((a, b) => a.client.id - b.client.id)
        },
        lpas() {
            return this.transaction.lpas.sort((a, b) => a.client.id - b.client.id)
        },
        // progress
        clientProfileProgress() {
            // return percentage complete for each client profile
            let result = []
            let tF = [true, false]

            // guards
            if (!this.transaction) return result

            for (let i in this.clients) {
                // init variable
                let completed = 0
                let questions = 0 // amount of required questions
                let percentage = 0
                let errors = []
                let client = this.clients[i]

                // add questions and percentages
                questions += 1
                if (client.profileNameTitle) completed += 1
                else errors.push('Title missing')

                questions += 1
                if (client.profileNameFirst) completed += 1
                else errors.push('First name missing')

                questions += 1
                if (client.profileNameLast) completed += 1
                else errors.push('Last name missing')

                questions += 1
                if (tF.includes(client.profileAliasYN)) completed += 1
                else errors.push('Also known as missing')

                questions += 1
                if (client.profileAliasYN) {
                    questions += 1
                    if (client.profileAlias) {
                        completed += 1
                    } else errors.push('Also known as detail missing')
                }

                // questions += 1
                // if (tF.includes(client.profilePreviousNameYN)) completed += 1
                // else errors.push('Previous name missing')

                // if (client.profilePreviousNameYN) {
                //     // skipp-able questions (may want commenting out)
                //     questions += 3
                //     if (client.profilePreviousName) {
                //         completed += 1
                //     } else errors.push('Previous name details missing')
                //     if (client.profilePreviousNameChangedDate) {
                //         completed += 1
                //     } else errors.push('Previous name date missing')
                //     if (client.profilePreviousNameChangedMethod) {
                //         completed += 1
                //     } else errors.push('Previous name method missing')
                // }

                questions += 1
                if (client.profileSex) completed += 1
                else errors.push('Gender missing')

                questions += 1
                if (client.profileDOB) completed += 1
                else errors.push('Date of birth missing')

                if (client.profileNationality) completed += 1
                else errors.push('Nationality missing')


                // if (tF.includes(client.profileDiplomaticImmunity)) completed += 1
                // else errors.push('Diplomatic status missing')

                questions += 1
                if (client.profileRelationshipStatus) completed += 1
                else errors.push('Relationship status missing')

                questions += 1
                if (tF.includes(client.profileRelationshipCurrentlyYN)) {
                    completed += 1

                    if (client.profileRelationshipCurrentlyYN) {
                        questions += 1
                        if (client.profileRelationshipCurrentStatus) completed += 1
                        else errors.push('Client current relationship status missing')

                        questions += 1
                        if (client.profileRelationshipDuration) completed += 1
                        else errors.push('Client Relationship duration missing')
                    }

                } else errors.push('Currently in a relationship missing')


                questions += 1
                if (tF.includes(client.profileRelationshipPreviousYN)) {
                    completed += 1
                    if (client.profileRelationshipPreviousYN) {
                        questions += 1
                        if (client.profileRelationshipPreviousStatus) completed += 1
                        else errors.push('Previous relationship ending missing')

                        questions += 1
                        if (client.profileRelationshipPreviousPersonName) completed += 1
                        else errors.push('Previous relationship person name missing')
                    }
                } else errors.push('Been in a previous marriage missing')


                // if (client.profileMobileNumber) completed += 1
                // else errors.push('Mobile number missing')
                //
                // if (client.profileEmail) completed += 1
                // else errors.push('Email missing')

                questions += 1
                if (tF.includes(client.profileMarketingConsent)) completed += 1
                else errors.push('Marketing consent missing')

                questions += 1
                if (client.current_address) completed += 1
                else errors.push('Current address is missing')

                // if (client.profileAddressMovedIn) completed += 1
                // else errors.push('Address moved in date missing')
                //
                // if (client.profileAddressOccupancy) completed += 1
                // else errors.push('Address occupancy missing')
                //
                // if (tF.includes(client.profileAddressCorrespondenceYN)) completed += 1
                // else errors.push('Address correspondence missing')

                // let now = new Date()
                // let addressHistoryYearsRequired = 5
                // if (client.profileAddressMovedIn &&
                //     now.setFullYear(now.getFullYear() - addressHistoryYearsRequired) <
                //     new Date(client.profileAddressMovedIn)) {
                //     questions += 1
                //     if (client.previous_address && client.previous_address.length) completed += 1
                //     else errors.push('Previous address history missing')
                // }

                questions += 1
                if (tF.includes(client.children_have)) completed += 1
                else errors.push('Have children missing')

                if (client.children_have) {
                    questions += 1
                    if (client.people && client.people.filter(p => this.isSonOrDaughter(p)).length) completed += 1
                    else errors.push('Children details missing')
                }

                questions += 1
                if (tF.includes(client.profilePregnant)) completed += 1
                else errors.push('Expecting a child missing')

                if (client.profilePregnant) {
                    questions += 1
                    if (client.profilePregnantDueDate) {
                        completed += 1
                    } else errors.push('Expecting a child date missing')
                }

                questions += 1
                if (tF.includes(client.dependants_have)) completed += 1
                else errors.push('Client other dependants missing')

                if (client.dependants_have) {
                    questions += 1
                    if (client.people && client.people.filter(p => this.isDependant(p)).length) completed += 1
                    else errors.push('Dependants details missing')
                }

                questions += 1
                if (tF.includes(client.assets_other_high_value)) completed += 1
                else errors.push('Other high value assets missing')

                if (client.assets_other_high_value) {
                    questions += 1
                    if (client.assets && client.assets.filter(a => !this.assetsOtherNot.includes(a.assetType)).length) completed += 1
                    else errors.push('Other asset details missing')
                }

                questions += 1
                if (tF.includes(client.policies_other_type)) completed += 1
                else errors.push('Other policies missing')

                if (client.policies_other_type) {
                    questions += 1
                    if (client.policies && client.policies.filter(p => !this.policiesOthersNot.includes(p.policyType)).length) completed += 1
                    else errors.push('Other policies details missing')
                }

                questions += 1
                if (tF.includes(client.mortgage_debts)) completed += 1
                else errors.push('Mortgage debts missing')

                if (client.mortgage_debts) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => this.liabilitiesMortgage.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Mortgage details missing')
                }

                questions += 1
                if (tF.includes(client.unsecured_loans)) completed += 1
                else errors.push('Unsecured loans missing')

                if (client.unsecured_loans) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => this.liabilitiesLoans.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Unsecured loan details missing')
                }

                questions += 1
                if (tF.includes(client.credit_card_balances)) completed += 1
                else errors.push('Credit cards missing')

                if (client.credit_card_balances) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => this.liabilitiesCreditCards.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Credit card details missing')
                }

                questions += 1
                if (tF.includes(client.other_credit_agreements)) completed += 1
                else errors.push('Other credit agreements missing')

                if (client.other_credit_agreements) {
                    questions += 1
                    if (client.liabilities && client.liabilities.filter(l => !this.liabilitiesOtherNot.includes(l.liabilityType)).length) completed += 1
                    else errors.push('Other credit agreements details missing')
                }
                //
                // questions += 1
                // if (tF.includes(client.profileBankruptcyIVAYN)) completed += 1
                // else errors.push('Bankruptcy missing')
                //
                // questions += 1
                // if (tF.includes(client.profileDebtManagementYN)) completed += 1
                // else errors.push('Debt management missing')

                // questions += 1
                // if (tF.includes(client.profileCCJYN)) completed += 1
                // else errors.push('CCJ missing')
                //
                // questions += 1
                // if (tF.includes(client.profileMissedYN)) completed += 1
                // else errors.push('Missed payment missing')

                // if (client.commitments && client.commitments.length) completed += 1
                // else errors.push('Commitments details missing')

                // calculate percentage
                percentage = Number(Number((completed / questions) * 100).toFixed(0))

                // add percentage to return array
                result.push({percentage: percentage, errors: errors, client: client})
            }
            return result
        },
        estatePlanningProgress() {
            // return percentage complete for each client profile
            let result = []
            let tF = [true, false]

            // guards
            if (!this.transaction) return result

            // start
            for (let i in this.estatePlanningSurveys) {
                // init variable
                let completed = 0
                let questions = 0 // amount of required questions
                let percentage = 0
                let errors = []
                let survey = this.estatePlanningSurveys[i]
                let client = this.clients.find(client => client.id === this.estatePlanningSurveys[i].client)

                // health
                questions += 1
                if (tF.includes(client.health_status)) completed += 1
                else errors.push('Health illness status missing')

                if (client.health_status) {
                    questions += 1
                    if (client.health_issues) completed += 1
                    else errors.push('Health illness details missing')
                }

                questions += 1
                if (tF.includes(client.capacity_status)) completed += 1
                else errors.push('Health capacity status missing')

                if (client.capacity_status) {
                    questions += 1
                    if (client.capacity_issues) completed += 1
                    else errors.push('Health capacity details missing')
                }

                questions += 1
                if (tF.includes(client.mobility_status)) completed += 1
                else errors.push('Health mobility status missing')

                if (client.mobility_status) {
                    questions += 1
                    if (client.mobility_issues) completed += 1
                    else errors.push('Health mobility details missing')
                }

                questions += 1
                if (tF.includes(client.language_status)) completed += 1
                else errors.push('Health language status missing')

                if (client.language_status) {
                    questions += 1
                    if (client.language_issues) completed += 1
                    else errors.push('Health language details missing')
                }

                // existing will
                questions += 1
                if (tF.includes(survey.will_existing)) completed += 1
                else errors.push('Existing products Will status missing')

                if (survey.will_existing) {
                    questions += 1
                    if (survey.will_existing_drafts_person) completed += 1
                    else errors.push('Existing Will drafts person missing')

                    questions += 1
                    if (survey.will_existing_year) completed += 1
                    else errors.push('Existing Will drafts person missing')

                    questions += 1
                    if (tF.includes(survey.changes_relationship)) completed += 1
                    else errors.push('Existing Will relationship change status missing')

                    if (survey.changes_relationship) {
                        questions += 1
                        if (survey.changes_relationship_details) completed += 1
                        else errors.push('Existing Will relationship change details missing')
                    }

                    questions += 1
                    if (tF.includes(survey.changes_dependants)) completed += 1
                    else errors.push('Existing Will changes to dependants status missing')

                    if (survey.changes_dependants) {
                        questions += 1
                        if (survey.changes_dependants_details) completed += 1
                        else errors.push('Existing Will relationship change details missing')
                    }

                    questions += 1
                    if (tF.includes(survey.changes_appointed)) completed += 1
                    else errors.push('Existing Will changes to appointed people status missing')

                    if (survey.changes_appointed) {
                        questions += 1
                        if (survey.changes_appointed_details) completed += 1
                        else errors.push('Existing Will changes to appointed people details missing')
                    }

                    questions += 1
                    if (tF.includes(survey.changes_estate)) completed += 1
                    else errors.push('Existing Will changes to estate status missing')

                    if (survey.changes_estate) {
                        questions += 1
                        if (survey.changes_estate_details) completed += 1
                        else errors.push('Existing Will changes to estate details missing')
                    }

                    questions += 1
                    if (tF.includes(survey.changes_will_other)) completed += 1
                    else errors.push('Existing Will other changes status missing')

                    if (survey.changes_will_other) {
                        questions += 1
                        if (survey.changes_will_other_details) completed += 1
                        else errors.push('Existing Will other changes details missing')
                    }

                    if (this.willExistingOverseas(survey)) {
                        questions += 1
                        if (tF.includes(survey.will_existing_overseas)) completed += 1
                        else errors.push('Existing Will overseas status missing')
                    }
                }

                // existing lpa
                questions += 1
                if (tF.includes(survey.lpa_existing)) completed += 1
                else errors.push('Existing Lpa status missing')

                if (survey.lpa_existing) {
                    questions += 1
                    if (survey.lpa_existing_type) completed += 1
                    else errors.push('Existing Lpa type missing')

                    questions += 1
                    if (tF.includes(survey.lpa_changes)) completed += 1
                    else errors.push('Existing Lpa changes status missing')

                    if (survey.lpa_changes) {
                        questions += 1
                        if (survey.lpa_changes_details) completed += 1
                        else errors.push('Existing Lpa changes details missing')
                    }
                }

                // existing funeral
                if (this.funeralPlanHas(survey)) {
                    questions += 1
                    if (tF.includes(survey.funeral_plan_has)) completed += 1
                    else errors.push('Existing funeral plan status missing')

                    if (this.funeralPlanFunds(survey)) {
                        questions += 1
                        if (tF.includes(survey.funeral_plan_funds)) completed += 1
                        else errors.push('Existing funeral funds status missing')
                    }
                }

                // business
                if (this.businessContinue(survey)) {
                    questions += 1
                    if (tF.includes(survey.business_continue)) completed += 1
                    else errors.push('Existing business to continue status missing')
                }

                if (this.businessOtherShareHolders(survey)) {
                    questions += 1
                    if (tF.includes(survey.business_other_shareholders)) completed += 1
                    else errors.push('Existing business other shareholders status missing')
                }

                if (this.businessShareHoldersAgreement(survey)) {
                    questions += 1
                    if (tF.includes(survey.business_shareholders_agreement)) completed += 1
                    else errors.push('Existing business shareholders agreement status missing')
                }

                // relationship
                // divorce
                if (client.profileRelationshipStatus === 'divorced' || client.profileRelationshipPreviousStatus === 'divorced') {
                    questions += 1
                    if (tF.includes(survey.divorce_spouse_remarried)) completed += 1
                    else errors.push('Spouse remarried missing')

                    if (survey.divorce_spouse_remarried === false) {
                        questions += 1
                        if (tF.includes(survey.divorce_consent_order_signed)) completed += 1
                        else errors.push('Consent order signed missing')
                    }

                    if (survey.divorce_consent_order_signed === false) {
                        questions += 1
                        if (client.profileRelationshipPreviousPersonName) completed += 1
                        else errors.push('Previous partner name missing')
                    }

                }

                // separation
                if (client.profileRelationshipStatus === 'separated' || client.profileRelationshipPreviousStatus === 'separated') {
                    questions += 1
                    if (tF.includes(survey.separation_divorce_proceedings_started)) completed += 1
                    else errors.push('Divorce proceedings started missing')

                    questions += 1
                    if (tF.includes(survey.separation_agreement_signed)) completed += 1
                    else errors.push('Divorce consent order signed missing')

                    questions += 1
                    if (client.profileRelationshipPreviousPersonName) completed += 1
                    else errors.push('Previous partner name missing')

                }
                // widowed
                if (client.profileRelationshipStatus === 'widowed' || client.profileRelationshipPreviousStatus === 'widowed') {
                    questions += 1
                    if (survey.widowed_year_of_death) completed += 1
                    else errors.push('Widowed; year of death missing')

                    questions += 1
                    if (tF.includes(survey.widowed_distribution_to_other)) completed += 1
                    else errors.push('Widowed; distribution left to others YN missing')

                    if (survey.widowed_distribution_to_other) {
                        questions += 1
                        if (survey.widowed_distribution_to_other_details) completed += 1
                        else errors.push('Widowed; distribution to others details missing')
                    }
                }

                // distribution
                if (this.willDistributionResidentialPartner(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_residential_partner)) completed += 1
                    else errors.push('Distribution to partner status missing')
                }
                if (this.willDistributionTenantsInCommon(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_tenants_in_common)) completed += 1
                    else errors.push('Distribution tenants in common status missing')
                }
                if (this.willDistributionPartnerResideDetails(survey)) {
                    questions += 1
                    if (survey.will_distribution_partner_reside_details) completed += 1
                    else errors.push('Distribution partner reside details missing')
                }
                if (this.willDistributionResidentialChildrenAfter(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_residential_children_after)) completed += 1
                    else errors.push('Distribution to children status missing')
                }
                if (this.willDistributionEstateToDetails(survey)) {
                    questions += 1
                    if (survey.will_distribution_estate_to_details) completed += 1
                    else errors.push('Distribution to details missing')
                }
                if (this.willDistributionOtherAssetsToPartner(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_other_assets_to_partner)) completed += 1
                    else errors.push('Distribution other assets to partner status missing')
                }
                if (this.willDistributionOtherAssetsDetails(survey)) {
                    questions += 1
                    if (survey.will_distribution_other_assets_details) completed += 1
                    else errors.push('Distribution other assets to details missing')
                }
                if (this.willDistributionAllChildrenBeneficiaries(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_all_children_beneficiaries)) completed += 1
                    else errors.push('Distribution children all are beneficiaries status missing')
                }
                if (this.willDistributionChildrenExcludedDetails(survey)) {
                    questions += 1
                    if (survey.will_distribution_children_excluded_details) completed += 1
                    else errors.push('Distribution children excluded details missing')
                }
                if (this.willDistributionOtherPropertiesOccupiedFriends(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_other_properties_occupied_friends)) completed += 1
                    else errors.push('Distribution other properties occupied by friends status missing')
                }
                if (this.willDistributionOtherPropertiesOccupiedFriendsDetails(survey)) {
                    questions += 1
                    if (survey.will_distribution_other_properties_occupied_friends_details) completed += 1
                    else errors.push('Distribution other properties occupied by friends details missing')
                }
                if (this.willDistributionOtherPropertiesOccupiedFriendsContinue(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_other_properties_occupied_friends_continue)) completed += 1
                    else errors.push('Distribution other properties occupied by friends to continue status missing')
                }
                if (this.willDistributionBeneficiariesIllnessWorking(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_beneficiaries_illness_working)) completed += 1
                    else errors.push('Distribution beneficiaries illness status missing')
                }
                // if (this.willDistributionBeneficiariesIllnessWorkingDetails(survey)) {
                //     questions += 1
                //     if (survey.will_distribution_beneficiaries_illness_working_details) completed += 1
                //     else errors.push('Distribution beneficiaries illness details missing')
                // }
                if (this.willDistributionBeneficiariesManagingFinances(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_beneficiaries_managing_finances)) completed += 1
                    else errors.push('Distribution beneficiaries difficulty managing finances status missing')
                }
                // if (this.willDistributionBeneficiariesManagingFinancesDetails(survey)) {
                //     questions += 1
                //     if (survey.will_distribution_beneficiaries_managing_finances_details) completed += 1
                //     else errors.push('Distribution beneficiaries difficulty managing finances details missing')
                // }
                if (this.willDistributionBeneficiariesFinancialVulnerable(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_beneficiaries_financial_vulnerable)) completed += 1
                    else errors.push('Distribution beneficiaries financially vulnerable status missing')
                }
                // if (this.willDistributionBeneficiariesFinancialVulnerableDetails(survey)) {
                //     questions += 1
                //     if (survey.will_distribution_beneficiaries_financial_vulnerable_details) completed += 1
                //     else errors.push('Distribution beneficiaries financially vulnerable details missing')
                // }
                if (this.willDistributionBeneficiariesStateBenefits(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_beneficiaries_state_benefits)) completed += 1
                    else errors.push('Distribution beneficiaries state benefits status missing')
                }
                // if (this.willDistributionBeneficiariesStateBenefitsDetails(survey)) {
                //     questions += 1
                //     if (survey.will_distribution_beneficiaries_state_benefits_details) completed += 1
                //     else errors.push('Distribution beneficiaries state benefits details missing')
                // }
                if (this.willDistributionBeneficiariesDrugs(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_beneficiaries_drugs)) completed += 1
                    else errors.push('Distribution beneficiaries drug problem status missing')
                }
                // if (this.willDistributionBeneficiariesDrugsDetails(survey)) {
                //     questions += 1
                //     if (survey.will_distribution_beneficiaries_drugs_details) completed += 1
                //     else errors.push('Distribution beneficiaries drug problem details missing')
                // }
                if (this.willDistributionBeneficiariesDivorced(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_beneficiaries_divorced)) completed += 1
                    else errors.push('Distribution beneficiaries divorced status missing')
                }
                // if (this.willDistributionBeneficiariesDivorcedDetails(survey)) {
                //     questions += 1
                //     if (survey.will_distribution_beneficiaries_divorced_details) completed += 1
                //     else errors.push('Distribution beneficiaries divorced details missing')
                // }
                // if (this.willDistributionEstateTaxable(survey)) {
                //     questions += 1
                //     if (tF.includes(survey.will_distribution_estate_taxable)) completed += 1
                //     else errors.push('Distribution taxable estate status missing')
                // }
                if (this.willDistributionPensionCollecting(survey)) {
                    questions += 1
                    if (tF.includes(survey.will_distribution_pension_collecting)) completed += 1
                    else errors.push('Distribution pension collecting status missing')
                }
                if (this.willDistributionPensionNominationDetails(survey)) {
                    questions += 1
                    if (survey.will_distribution_pension_nomination_details) completed += 1
                    else errors.push('Distribution pension nomination details missing')
                }

                // insurance
                if (this.lifeInsurance(survey)) {
                    questions += 1
                    if (tF.includes(survey.life_insurance_all_in_trust)) completed += 1
                    else errors.push('Life insurance all in trust status missing')

                    questions += 1
                    if (tF.includes(survey.life_insurance_professionally_arranged)) completed += 1
                    else errors.push('Life insurance professionally arranged status missing')

                    if (survey.life_insurance_professionally_arranged === false) {
                        questions += 1
                        if (survey.life_insurance_arranged_details) completed += 1
                        else errors.push('Life insurance arranged by details missing')
                    }

                    questions += 1
                    if (tF.includes(survey.life_insurance_reviewed_recently)) completed += 1
                    else errors.push('Life insurance last reviewed status missing')

                } else {
                    questions += 1
                    if (tF.includes(survey.life_insurance_none)) completed += 1
                    else errors.push('No life insurance status missing')
                }

                // financial advisor
                if (this.financialAdvisor(survey)) {
                    questions += 1
                    if (tF.includes(survey.financial_advisor)) completed += 1
                    else errors.push('Financial advisor status missing')

                }

                // mortgage
                if (this.mortgageEarlyRepaymentCharge(survey)) {
                    questions += 1
                    if (tF.includes(survey.mortgage_early_repayment_charge)) completed += 1
                    else errors.push('Mortgage early repayment change status missing')
                }

                // calculate percentage
                percentage = Number(Number((completed / questions) * 100).toFixed(0))

                // add percentage to return array
                result.push({percentage: percentage, errors: errors, client: client, estatePlanningSurvey: survey})
            }
            return result
        },
        willInstructionsProgress() {
            return []
        },
        lpaInstructionsProgress() {
            return []
        },
        clientProfilesSectionComplete() {
            return this.clientProfileProgress.filter(
                c => c.percentage === 100).length === this.clientProfileProgress.length
        },
        estatePlanningSurveysComplete() {
            return this.estatePlanningProgress.filter(
                c => c.percentage === 100).length === this.estatePlanningProgress.length
        },
        recommendationsComplete() {
            return !!this.transaction.estate_planning_custom_recommendation.filter(r => r.auto_created || r.accepted).length
        },
        invoiceComplete() {
            // has invoice items been added (this indicates either payment has been taken or payment skipped for now)
            let invoices = this.transaction.invoice_transaction;
            return (
                !!invoices.length &&
                !!invoices.reduce((acc, invoice) => [...acc, ...invoice.invoice_items], []).length
            );
        },
        fullPaymentReceived() {
            if (!this.transaction.invoice_transaction.length) return false

            let invoiceTotal = this.transaction.invoice_transaction.reduce((acc, i) => acc + i.total, 0)
            let paymentsReceived = this.transaction.invoice_transaction.reduce((acc, i) => acc.concat(i.invoice_payments), [])
            let paymentTotals = paymentsReceived.reduce((acc, p) => acc + p.amount, 0)

            // must have an active payment plan
            let hasPaymentPlan = paymentsReceived.find(payment => payment.first_data_schedule_order_id && payment.first_data_schedule_active)
            console.log('paymentsReceived', paymentsReceived)
            console.log('hasPaymentPlan', hasPaymentPlan)
            return (paymentTotals >= invoiceTotal && invoiceTotal !== 0) || hasPaymentPlan
        },
        estatePlanningSectionComplete() {
            return this.clientProfilesSectionComplete && this.estatePlanningSurveysComplete
        },
        recommendationSectionComplete() {
            return this.clientProfilesSectionComplete && this.estatePlanningSurveysComplete && this.recommendationsComplete
        },
        invoiceSectionComplete() {
            return this.clientProfilesSectionComplete && this.estatePlanningSurveysComplete && this.recommendationsComplete && this.invoiceComplete
        },
        productInstructionsSectionComplete() {
            // are all wills and lpa products complete?
            // join lpa and wills
            let products = [...this.wills, ...this.lpas]
            // only one product instruction is required to be complete
            if (products.length) return products.filter(product => product.status === 'awaiting_checking').length >= 1
            return false
        },
        invoiceSetupSectionComplete() {
            return this.invoiceComplete
        },
        currentHandoverStep() {
            if (this.hasHandedOver) return ''
            if (!this.estatePlanningSurveysComplete && !this.clientProfilesSectionComplete) return 'before_client_profile'
            else if (this.estatePlanningSurveysComplete && !this.invoiceSectionComplete) return 'after_estate_planning_surveys'
            else if (this.invoiceSetupSectionComplete && !this.productInstructionsSectionComplete) return 'after_deposit'
            else if (this.productInstructionsSectionComplete && this.invoiceSectionComplete) return 'after_product_instructions'
            return 'before_client_profile'
        },
        hasHandedOver() {
            return !!this.transaction.handover_point;
        },
    },
    methods: {
        refreshDocuments() {
            this.$refs.documents.documentsFetch();
        }
    }
}
