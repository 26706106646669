<template>
  <div>
    <div
        class="d-flex align-items-center page-num-container pt-80"
        :class="{'completed': progress.percentage === 100}"
    >
      <div class="page-num"
           :style="'background: conic-gradient(#ffffff 0deg ' + (360/100 * progress.percentage) + 'deg,#f6f7fa 80deg 190deg)'">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon"/>
      </div>
      <h4>Property Details</h4>
    </div>
    <b-row>
      <b-col lg="7">
        <p class="mb-lg-32">Below is a detailed questionnaire about the property that you are mortgaging. Please complete this section and notify your mortgage advisor when you have found a property.</p>
      </b-col>
    </b-row>
    <b-row class="pt-20">
      <b-col md="4">
        <MortgagePropertySurveyCard
            :progress="progress"
            :transaction="transaction"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MortgagePropertySurveyCard from "./cards/MortgagePropertySurvey";

export default {
  name: 'MortgagePropertySurvey',
  components: {MortgagePropertySurveyCard},
  props: {
    transaction: {
      type: Object,
      required: true
    },
    progress: {
      type: Object,
      required: true
    }
  }
}
</script>