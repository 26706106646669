<template>
  <div>
    <div class="section-title">
      <h4 class="p-3 mb-2">
        <span>Documents</span>
      </h4>
    </div>

    <div>
      <DocumentSection
          ref="documents"
          :title=null
          :locked="false"
          :transaction="transaction">
      </DocumentSection>
    </div>
  </div>
</template>

<script>
import DocumentSection from "@/components/common/documents/DocumentSection";

export default {
  name: 'Documents',
  components: {DocumentSection},
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    documentsFetch() {
      this.$refs.documents.documentsFetch()
    }
  }
}
</script>
