<template>
  <b-card body-class="p-0" class="trans-card">
    <div class="d-flex justify-content-between align-items-center p-3">
      <div class="d-flex align-items-center">
        <div class="img-wrapper pr-3">
          <img alt="cardImage" :src="image"/>
        </div>
        <div class="d-flex align-items-start flex-column">
          <h5 class="">
            {{ clientNameList({clients: mortgage.clients}) }}
          </h5>
          <h6 class="mb-0 text-mute">Mortgage - {{mortgage.mortgage_recommended.lender }}</h6>
        </div>
      </div>
    </div>
    <div class="bg-gray-100 border-top border-gray-200">
      <b-progress-bar
          :value="status[2]"
          variant="primary">
      </b-progress-bar>
    </div>
    <div
        class="
        d-flex
        align-items-center
        justify-content-start
        pl-3
        pr-1
        pt-8
        pb-8
      "
    >
      <div
          class="badge text-10 p-1"
          :class="status[1]">
        {{ status[0] }}
      </div>
    </div>
  </b-card>
</template>

<script>
import {clientHelpers} from "@/mixins/clientHelpers";

export default {
  name: "MortgageCard",
  mixins: [clientHelpers],
  props: {
    mortgage: {
      type: Object,
      required: true,
    },
  },
  computed: {
    status() {
      // return status text and badge colour
      let string = this.mortgage.STATUS_CHOICES.find(
          (option) => option[0] === this.mortgage.status
      )[1];
      let progress = 20;
      let variant = "badge-primary";
      if (this.mortgage.status === "awaiting_log_submit") {
        variant = "badge-primary";
        progress = 30;
      }
      else if (this.mortgage.status === "awaiting_offer") {
        variant = "badge-primary";
        progress = 60;
      }
      else if (this.mortgage.status === "awaiting_exchange") {
        variant = "badge-primary";
        progress = 70;
      }
      else if (this.mortgage.status === "awaiting_completion") {
        variant = "badge-primary";
        progress = 80;
      }
      else if (this.mortgage.status === "awaiting_proc") {
        variant = "badge-primary";
        progress = 90;
        string = 'Complete'
      }
      else if (this.mortgage.status === "end_proc_received") {
        variant = "badge-success";
        progress = 100;
        string = 'Complete'
      }
      else if (this.mortgage.status === "end_ntu") {
        variant = "badge-danger";
        progress = 100;
        string = 'Cancelled'
      }

      return [string, variant, progress];
    },
    image() {
      // image for card
      if (this.mortgage.clients.length > 1) {
        return require("@/assets/images/group.svg");
      } else if (this.mortgage.clients[0].profileSex === 'male') {
        return require("@/assets/images/man-profile.svg")
      }
      return require("@/assets/images/woman-profile.svg");
    },
  },
};
</script>
