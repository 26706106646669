<template>
  <div>
    <div :class="{ completed: completed }"
         class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num"></div>
      <h4 class="trans-section-title">Product Instructions</h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">
          Detailed instructions need to be taken for each recommendation. Click on each product / service to complete your instructions.</p>
      </b-col>
    </b-row>
    <b-row class="pt-20 trans-section-body">

      <b-col v-for="(will, index) in wills"
             :key="`will${index}`"
             md="4">
        <WillCard :will="will" />
      </b-col>

      <b-col v-for="(lpa, index) in lpas"
             :key="`lpa${index}`"
             md="4">
        <LpaCard :lpa="lpa" />
      </b-col>

      <b-col v-for="(storage, index) in transaction.storages"
             :key="`storage${index}`"
             md="4">
        <StorageCard :storage="storage" :transaction="transaction" />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import WillCard from "./cards/Will";
import LpaCard from "./cards/Lpa";
import StorageCard from "./cards/Storage";

export default {
  name: "Products",
  components: {
    StorageCard,
    LpaCard,
    WillCard,
  },
  props: {
    lpas: {
      type: Array,
      required: true,
    },
    wills: {
      type: Array,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
  }
};
</script>
