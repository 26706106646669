import {http} from "@/services";
import {toast} from '@/mixins/toast'
import {clientHelpers} from "@/mixins/clientHelpers";

export const handoverHelpers = {
    name: 'handoverHelpers',
    mixins: [toast, clientHelpers],
    computed: {
        bookingInformationHTML() {
            if (this.booking?.transaction?.clients) {
                return `<h5>Book an ${this.booking.appointmentType} appointment with us on behalf of your clients ${this.clientNameList({clients: this.booking.transaction.clients})}</h5>`
            }
            return null
        }
    },
    methods: {
        setProductModal(value, sendInstructions = false) {
            // maybe specific to estate planning product creation only...
            this.show.products = value
            this.show.productModal = true
            this.show.sendInstructions = sendInstructions
        },
        createTransaction(appointmentType) {
            this.booking.appointmentType = appointmentType
            this.show.productModal = true
        },
        bookingComplete() {
            this.$emit('refreshHandedOver')
        },
        handoverComplete(data) {
            this.booking.transaction = data
            this.$nextTick(() => {
                this.show.bookingModal = true
                this.$emit('refreshHandedOver')
            })
        },
        async handoverCompleteNoAppointment() {
            await this.sendBookingInvitation()
            await this.sendReferralNotification()
            this.$emit('refresh')
        },
        async sendBookingInvitation() {
            // sends client an invitation to book an appointment

            if (!this.booking?.transaction?.id) {
                this.toastError('Booking transaction ID missing')
                return Promise.reject()
            }

            let data = {
                email_template: 'booking_invitation_handover',
                transaction: this.booking.transaction.id,
                role: this.booking.hostRole,
                location: 'Video Call',
                type: this.booking.appointmentType
            }
            return http.post('send_appointment_invitation', data).then(
                () => {
                    this.toast('Client has been sent an invitation to book an appointment')
                    this.show.bookingModal = false
                    return Promise.resolve()
                }
            ).catch(
                error => {
                    console.log(error)
                    this.toastError(error.response.data)
                    return Promise.reject()
                }
            )
        },
        async sendReferralNotification() {
            // sends a notification to staff member that a client has been referred from an introducer

            if (!this.booking?.transaction?.id) {
                this.toastError('Booking transaction ID missing')
                return Promise.reject()
            }

            let data = {
                transaction_id: this.booking.transaction.id
            }

            return http.post('send_referral_notification', data).then(
                () => {
                }
            ).catch(
                error => {
                    console.log(error)
                }
            )
        }
    }
}