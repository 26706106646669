<template>
  <b-card
      body-class="p-0 p-20"
      footer-class="apt_bottom"
      class="bg-primary w-100 mb-30"
      :class="{'cursor-pointer': event.status === 'Scheduled' && status[0] !== 'Past Event'}"
      style="min-height: 170px"
      @click="reschedule"
  >
      <div
          class="ribbon ribbon-top-right"
          :class="status[1]"
      >
        <span>{{ status[0] }}</span>
      </div>
      <img
          :src="require('@/assets/images/trans-banner.png')"
          alt="shield"
          class="btn-background-img"
          style="height: 70%"
      />

      <div class="text-left d-inline-block">
        <div class="text-white">{{ event.type }}</div>
        <h4 class="text-white d-inline">
          {{ formatDateDescription(event.startDateTime) }}
          <span class="font-weight-400">
          {{ formatTime(event.startDateTime) }}</span
          >
        </h4>
        <div v-if="event.owner && event.owner.name" class="text-white">
          With {{ event.owner.name }}
<!--          <b-avatar-->
<!--              v-if="imageThumb"-->
<!--              :src="imageThumb"-->
<!--              variant="info"-->
<!--          ></b-avatar>-->

        </div>
      </div>
      <template #footer v-if="showReschedule && event.status === 'Scheduled' && status[0] !== 'Past Event'">
        Click to Reschedule
      </template>
  </b-card>
</template>

<script>
import {dateFormat} from "@/mixins/dateFormat";
import {currencyFormat} from "@/mixins/currencyFormat";

export default {
  name: "AppointmentCard",
  mixins: [currencyFormat, dateFormat],
  props: {
    event: {
      type: Object,
      required: true,
    },
    showReschedule: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    imageThumb() {
      let thumb = null;
      if (this.event.owner && this.event.owner.image_thumb) {
        return this.event.owner.image_thumb;
      }
      return thumb;
    },
    status() {
      if (['Cancelled by Advisor', 'Cancelled by Client'].includes(this.event.status)) {
        return ['Cancelled', 'warning']
      }
      if (['Missed'].includes(this.event.status)) {
        return ['Missed', 'danger']
      }
      if (new Date(this.event.startDateTime) < new Date()) {
        return ['Past Event', 'warning']
      }
      return [this.event.status, 'success']
    }
  },
  methods: {
    reschedule() {
      if (this.event.status !== "Scheduled" || this.status[0] === 'Past Event') return;
      this.$emit("reschedule");
    },
  },
};
</script>
<style>
.apt_bottom {
  transition: .2s;
  border-top: 1px rgba(128, 128, 128, 0.23) solid;
  height: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 0 0 10px 10px !important;
  background: #321433;
  color: white
}
</style>
