<template>
  <div v-if="transaction">
    <div class="hero-wrapper">
      <div class="hero trans-hero">
        <img
            alt="shield"
            class="trans-background"
            src="@/assets/images/trans-banner.png"
        />
        <div class="container">
          <h1 class="text-white">Your Estate Planning</h1>
          <h3 class="arrow-before text-white pl-30">
            Transaction<br/>
          </h3>
        </div>
      </div>
    </div>

    <div class="border-bottom-gray-200 pb-40 mt-12">
      <b-container>
        <b-row>
          <introduction/>
        </b-row>
      </b-container>
    </div>

    <div class="border-gray-200 border-wide-bottom">
      <b-container>
        <div class="border-wide-left border-gray-200 pl-32 pb-8">
          <ClientProfiles
              :client-profile-progress="clientProfileProgress"
              :transaction="transaction"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <EstatePlanning
              :estatePlanningProgress="estatePlanningProgress"
              :transaction="transaction"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <Products
              :completed="productInstructionsSectionComplete"
              :transaction="transaction"
              :wills="wills"
              :lpas="lpas"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <Appointments
              ref="appointments"
              :transaction="transaction"
              :showBookingButton="noFutureAppointments"
              help-text="These are your scheduled appointments. From here you can reschedule appointment if necessary."
              appointment-type="Estate Planning Consultation"
              host-role="Estate Planning Introducer"
              @refresh="$emit('refreshTransaction', $event)"
          />

          <document-request-section
              v-model="transaction"
              title="Below are details of documents requested by your estate planner."
          />
          <Documents :transaction="transaction" :locked="!fullPaymentReceived" />
        </div>
      </b-container>
    </div>
    <Contact
        class="mt-80 mb-80"
        :transaction="transaction"
        @refreshTransaction="$emit('refreshTransaction', $event)"
    />
  </div>
</template>

<script>
import {
  progressEstatePlanningHelpers
} from "@/views/introducer/transaction/detail/estatePlanning/progressEstatePlanningHelpers";

import DocumentRequestSection from "../../../../../components/common/documents/requests/DocumentRequestSection.vue";
import Introduction from "./Introduction.vue";
import ClientProfiles from "@/views/client/transaction/detail/estatePlanning/ClientProfiles";
import EstatePlanning from "@/views/client/transaction/detail/estatePlanning/EstatePlanning";
import Products from "@/views/client/transaction/detail/estatePlanning/Products";
import Appointments from "@/components/common/appointment";
import Contact from "@/components/common/contact/Contact";
import Documents from "@/views/client/transaction/detail/estatePlanning/Documents";

export default {
  name: "TransactionEstatePlanningDetail",
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  mixins: [progressEstatePlanningHelpers],
  components: {
    Documents,
    Contact,
    Appointments,
    Products,
    EstatePlanning,
    ClientProfiles,
    DocumentRequestSection,
    Introduction,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    noFutureAppointments () {
      return !this.transaction.events.filter(event => event.status === 'Scheduled' && new Date(event.endDateTime) > new Date()).length > 0
    }
  },
  data() {
    return {};
  },
};
</script>
