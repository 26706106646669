<template>
  <div>
    <div v-if="numbered" class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num"></div>
      <h4>Appointment</h4>
    </div>

    <div v-else class="section-title">
      <h4 class="p-3 mb-2">
        <span>Your Appointments</span>
      </h4>
    </div>

    <p v-if="helpText" class="mb-32">
      {{ helpText }}
    </p>

    <b-row :class="{ 'pt-20': numbered }">
      <b-col
          v-for="(event, index) in eventsSorted"
          :key="`event${index}`"
          md="6">
        <AppointmentCard
            :event="event"
            @reschedule="reschedule(event)"/>
      </b-col>
      <b-col v-if="showBookingButton" md="6">
        <div
            class="add-item mb-30 d-flex"
            style="min-height: 170px"
            @click="showBookingModal"
        >
          <h3>
            <i class="i-Add text-40"
               style="vertical-align: middle"
            ></i
            >
            Book Appointment
          </h3>
        </div>
      </b-col>
    </b-row>

    <BookingModal
        ref="bookingModal"
        v-model="show.bookingModal"
        class="booking"
        :transaction="transaction"
        :event="selected.event"
        :appointmentType="appointmentType"
        :hostRole="hostRole"
        :cancelAppointmentButton="!selected.event"
        :bookingInformationHTML="bookingInformationHTML"
        cancelText="Handover Without Booking Appointment"
        @cancel="handoverCompleteNoAppointment"
        @refresh="refresh"
    />
  </div>
</template>

<script>
import {http} from "@/services";
import { handoverHelpers} from "@/views/introducer/dashboard/createTransactions/handoverHelpers";

import BookingModal from "./calender/BookingModal";
import AppointmentCard from "./AppointmentCard";

export default {
  name: "Appointments",
  components: {
    AppointmentCard,
    BookingModal,
  },
  mixins: [handoverHelpers],
  mounted() {
    if (this.transaction) {
      this.events = this.transaction.events
    } else {
      this.getEvents()
    }
  },
  props: {
    transaction: {
      type: Object,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
    },
    showBookingButton: {
      type: Boolean,
      default: false
    },
    numbered: {
      type: Boolean,
      default: true
    },
    appointmentType: {
      type: String,
      required: true
    },
    hostRole: {
      type: String,
      required: true
    }
  },
  watch: {
    transaction: {
      deep: true,
      immediate: false,
      handler(value) {
        if (value) {
          this.init()
        }
      }
    }
  },
  data() {
    return {
      show: {
        bookingModal: false
      },
      selected: {
        event: null,
      },
      booking: {
        transaction: null
      },
      events: null
    };
  },
  computed: {
    scheduledEvents() {
      return this.events.filter((event) => event.status === "Scheduled");
    },
    user() {
      return this.$store.getters.user
    },
    eventsSorted() {
      // sort events by scheduled status first
      if (this.events) {
        let events = this.events
        return events.sort((a, b) => Number(b.status === 'Scheduled') - Number(a.status === 'Scheduled'))
      }
      return []
    }
  },
  methods: {
    init() {
      this.events = this.transaction.events
      this.booking.transaction = this.transaction
      this.booking.appointmentType = this.appointmentType
      this.booking.hostRole = this.hostRole
    },
    reschedule(event) {
      this.show.bookingModal = true;
      this.selected.event = event;
      setTimeout(() => {
        // needs delay as modal hasn't finished rendering before rebook becomes available
        this.$nextTick(() => {
          this.$refs.bookingModal.rebook()
        })
      }, 500)

    },
    clearSelected(event) {
      if (event === false) this.selected.event = null;
    },
    showBookingModal() {
      this.show.bookingModal = true;
    },
    refresh(event) {
      if (this.transaction) {
        this.$emit('refresh', event)
      } else {
        this.getEvents()
      }
    },
    getEvents() {
      http.get('client_events').then(
          response => {
            this.events = response.data
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    }
  },
};
</script>
