<template>
  <div>
    <div class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon" />
      </div>
      <h4 class="trans-section-title">Products</h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">
          These are the products you have proceeded with:</p>
      </b-col>
    </b-row>
    <b-row class="pt-20 trans-section-body">
      <b-col v-for="(policy, index) in transaction.policies"
             :key="`policy${index}`"
             md="4">
        <PolicyCard :policy="policy" />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import PolicyCard from "./cards/Policy";

export default {
  name: "Products",
  components: {
    PolicyCard,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
};
</script>
