<template>
  <b-modal
      v-model="showModal"
      aria-label=""
      body-class="modal-form"
      modal-class="modal-style-one modal-background-white questionnaire"
      no-close-on-backdrop
      scrollable
      size="lg"
      :disabled="show.loading"
      title="Who should receive this request?"
      @ok="ok"
      @shown="getAdvisors"
  >
  <b-overlay
        :show="show.loading"
        rounded="sm"
        spinner-type="border"
        spinner-variant="primary"
      >
      <div class="d-flex justify-content-between align-items-center">
      <b-link @click="selectAdvisor(advisor)" v-for="(advisor, index) in advisors"
            :key="index" class="m-2" :class="{'selected':selected_advisor==advisor,'unselected':selected_advisor!=advisor}">
        <div
            :class="[advisor.is_active ? 'user-active' : 'user-inactive']"
            class="user-card d-flex flex-column position-relative mt-30"
        >
          <h4 class="user-header position-relative">
            {{ advisor.name }}
            <div class="user-img">
              <img alt="" :src="image_thumb(advisor)"/>
            </div>
          </h4>
          <div
              class="user-info-wrapper text-12 p-20 d-flex justify-content-center"
          >
            <div class="user-info">
              <template v-if="advisor.job_title">
                <div class="text-right font-weight-500 pr-2">Role:</div>
                <div>{{ advisor.job_title }}</div>
              </template>

              <template v-if="advisor.contact_phone">
                <div class="text-right font-weight-500 pr-2">Phone:</div>
                <div>{{ advisor.contact_phone }}</div>
              </template>

              <template v-if="advisor.email">
                <div class="text-right font-weight-500 pr-2">Email:</div>
                <div>{{ advisor.email }}</div>
              </template>
            </div>
          </div>
        </div>        
      </b-link>

    </div>
    <hr data-content="OR" class="hr-text" />
    <div class="d-flex">
      <b-link @click="selectAdvisor(administrator)"
            class="m-2 w-100" :class="{'selected':selected_advisor==administrator,'unselected':selected_advisor!=administrator}">
        <div
            class="user-card d-flex flex-column position-relative mt-30"
        >
          <h4 class="user-header position-relative">
            {{ administrator.name }}
            <div class="user-img">
              <img alt="" :src="image_thumb(administrator)"/>
            </div>
          </h4>

          <div
              class="user-info-wrapper text-12 p-20 d-flex justify-content-center"
          >
            <div class="user-info">
            </div>
          </div>

        </div>        
      </b-link>
    </div>

  </b-overlay>

    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 m-0 d-flex">
        <button class="btn btn-backwards white-border" @click="cancel">
          Cancel
        </button>
        <button :disabled="show.loading||!selected_advisor" class="btn btn-forwards" @click="ok">
          Send
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {http} from "@/services";

// import AdvisorCard from "@/views/introducer/dashboard/AdvisorCard";


export default {
  name: 'AdvisorModal',
  props: [
    "value",
    "transaction",
  ],
  components:{
    // AdvisorCard,
  },
  data () {
    return {
      advisors: null,
      show: {
        loading: false,
      },
      selected_advisor: null,
      administrator: {name: 'Administrator', is_active:true},
    }
  },
  computed: {
    showModal: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    image_thumb(advisor) {
      let thumb = require('@/assets/images/man-profile.svg');
      if (advisor.image_thumb) {
        thumb = advisor.image_thumb;
      } else if (advisor.image) {
        thumb = advisor.image;
      }
      return thumb;
    },
    test(){
      console.log('I AM HERE!')
    },
    selectAdvisor(advisor){
      this.selected_advisor = advisor
      console.log('advisor', advisor)
      // this.$emit('selected',advisor)
      // this.showModal = false
    },
    getAdvisors() {
      this.show.loading=true;
      http.get('client_advisors').then(
          response => {
            this.advisors = response.data
            this.show.loading=false;
          }
      ).catch(
          error => {
            console.log(error)
            this.show.loading=false;
          }
      )
    },
    async ok(bvEvent = null) {
      // initiates payment
      if (bvEvent) bvEvent.preventDefault();

      this.$emit('submit',this.selected_advisor)
      this.showModal = false;

      // start
      this.show.loading = true;
    },
  }
}
</script>

<style scoped>
.selected{ border: #a58e50 10px solid !important;}
.unselected{border: transparent 10px solid;}

hr.hr-text {
  position: relative;
    border: none;
    height: 1px;
    background: #999;
}

hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>