<template>
  <div v-if="transaction">
    <div class="hero-wrapper">
      <div class="hero trans-hero">
        <img alt="shield"
             class="trans-background"
             src="@/assets/images/trans-banner.png">
        <div class="container">
          <h1 class="text-white">Insurance</h1>
          <h3 class="arrow-before text-white pl-30">
            Insurance transaction<br>
          </h3>

        </div>
      </div>
    </div>

    <Header :transaction="transaction"/>

    <div class="border-bottom-gray-200 pb-40 mt-12">
      <b-container>
        <b-row>
          <Introduction/>
        </b-row>
      </b-container>
    </div>

    <div class="border-gray-200 border-wide-bottom">
      <b-container>
        <div class="border-wide-left border-gray-200 pl-32 pb-8">
          <ClientProfiles
              :progress="clientProfileProgress"
              :transaction="transaction"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <Products :transaction="transaction"/>

          <Appointments
              ref="appointments"
              :transaction="transaction"
              :show-booking-button="noAppointments"
              help-text="Here are your upcoming appointments."
              appointment-type="Insurance Consultation"
              host-role="Insurance Introducer"
              @refresh="$emit('refreshTransaction', $event)"
          />

          <DocumentInsurance
              :transaction="transaction"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <document-request-section
              v-model="transaction"
              title="Below are details of your documents requested for this insurance transaction."
          />

        </div>
      </b-container>
    </div>

  </div>
</template>

<script>
import {progressInsuranceHelpers} from "@/views/introducer/transaction/detail/insurance/progressInsuranceHelpers";

import ClientProfiles from "@/views/client/transaction/detail/insurance/ClientProfiles";
import DocumentInsurance from "@/views/client/transaction/detail/insurance/DocumentInsurance";
import DocumentRequestSection from "@/components/common/documents/requests/DocumentRequestSection";
import Appointments from "@/components/common/appointment";
import Introduction from "./Introduction";
import Header from "./Header";
import Products from "./Products";

export default {
  name: 'TransactionInsuranceDetail',
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  mixins: [progressInsuranceHelpers],
  components: {
    Header,
    Introduction,
    Appointments,
    DocumentRequestSection,
    DocumentInsurance,
    ClientProfiles,
    Products
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    noAppointments() {
      return !this.transaction.events.length
    }
  },
  data() {
    return {}
  },
  methods: {
    showBookingModal() {
      this.$nextTick(() => {
        this.$refs.appointments.showBookingModal();
      });
    },
  }
}
</script>
