<template>
  <div>
    <div
      :class="{
        completed:
          clientProfileProgress.filter((c) => c.percentage === 100).length ===
          clientProfileProgress.length,
      }"
      class="d-flex align-items-center page-num-container pt-80"
    >
      <div class="page-num"></div>
      <h4>Your Profile{{ clientProfileProgress.length > 1 ? "s" : null }}</h4>
    </div>
    <b-row>
      <b-col lg="7">
        <p class="mb-lg-32">
          Click on your profile below to view or add basic information about
          your status, address, dependants, assets and liabilities.
          {{
            clientProfileProgress.length > 1
              ? " You cannot update your partners profile from here, instead they will need to login to their secure Client Tools area."
              : null
          }}
        </p></b-col
      >
    </b-row>

    <b-row class="pt-20">
      <b-col
        v-for="(clientProfile, index) in clientProfileProgress"
        :key="`clientProfile${index}`"
        md="4"
      >
        <ClientProfileCard
          v-if="user"
          :clientProfile="clientProfile"
          :disabled="clientProfile.client.id !== user.is_client.id"
          :transaction="transaction"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ClientProfileCard from "../mortgage/cards/ClientProfile";

export default {
  name: "ClientProfiles",
  components: { ClientProfileCard },
  props: {
    clientProfileProgress: {
      type: Array,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>